import {
  GINGIVAL_MARGIN,
  SOUNDING_DEPTH,
  TOOTH_PART,
  BLEEDING,
  PLAQUE,
  PUS,
  TARTAR,
  RESET,
  INIT_PAROD,
} from "../action";

const initState = {
  load: false,
  distoPalatal: {
    distoPalatal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  palatal: {
    palatal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  mesioPalatal: {
    mesioPalatal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  distoBuccal: {
    distoBuccal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  buccal: {
    buccal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  mesioBuccal: {
    mesioBuccal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  positiveData: false,
};

const parodontal22 = (state = initState, action) => {
  switch (action.type) {
    //REQUEST
    case INIT_PAROD:
      return {
        ...state,
        ...action?.payload,
        load: true,
      };
    case RESET + 22:
      return { ...initState, load: true };
    case TOOTH_PART + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          distoPalatal: action.payload === "distopalatal" && true,
        },
        palatal: {
          ...state.palatal,
          palatal: action.payload === "palatal" && true,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          mesioPalatal: action.payload === "mesiopalatal" && true,
        },
        distoBuccal: {
          ...state.distoBuccal,
          distoBuccal: action.payload === "distobuccal" && true,
        },
        buccal: {
          ...state.buccal,
          buccal: action.payload === "buccal" && true,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          mesioBuccal: action.payload === "mesiobuccal" && true,
        },
      };
    case SOUNDING_DEPTH + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          soundingDepth:
            state.distoPalatal.distoPalatal && action.payload !== "+/-"
              ? action.payload
              : state.distoPalatal.soundingDepth,
        },
        palatal: {
          ...state.palatal,
          soundingDepth:
            state.palatal.palatal && action.payload !== "+/-"
              ? action.payload
              : state.palatal.soundingDepth,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          soundingDepth:
            state.mesioPalatal.mesioPalatal && action.payload !== "+/-"
              ? action.payload
              : state.mesioPalatal.soundingDepth,
        },
        distoBuccal: {
          ...state.distoBuccal,
          soundingDepth:
            state.distoBuccal.distoBuccal && action.payload !== "+/-"
              ? action.payload
              : state.distoBuccal.soundingDepth,
        },
        buccal: {
          ...state.buccal,
          soundingDepth:
            state.buccal.buccal && action.payload !== "+/-"
              ? action.payload
              : state.buccal.soundingDepth,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          soundingDepth:
            state.mesioBuccal.mesioBuccal && action.payload !== "+/-"
              ? action.payload
              : state.mesioBuccal.soundingDepth,
        },
      };
    case GINGIVAL_MARGIN + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          gingivalMargin:
            state.distoPalatal.distoPalatal && action.payload !== "+/-"
              ? action.payload
              : state.distoPalatal.gingivalMargin,
        },
        palatal: {
          ...state.palatal,
          gingivalMargin:
            state.palatal.palatal && action.payload !== "+/-"
              ? action.payload
              : state.palatal.gingivalMargin,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          gingivalMargin:
            state.mesioPalatal.mesioPalatal && action.payload !== "+/-"
              ? action.payload
              : state.mesioPalatal.gingivalMargin,
        },
        distoBuccal: {
          ...state.distoBuccal,
          gingivalMargin:
            state.distoBuccal.distoBuccal && action.payload !== "+/-"
              ? action.payload
              : state.distoBuccal.gingivalMargin,
        },
        buccal: {
          ...state.buccal,
          gingivalMargin:
            state.buccal.buccal && action.payload !== "+/-"
              ? action.payload
              : state.buccal.gingivalMargin,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          gingivalMargin:
            state.mesioBuccal.mesioBuccal && action.payload !== "+/-"
              ? action.payload
              : state.mesioBuccal.gingivalMargin,
        },
        positiveData:
          action.payload === "+/-" ? !state.positiveData : state.positiveData,
      };
    case BLEEDING + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          bleeding: state.distoPalatal.distoPalatal
            ? !state.distoPalatal.bleeding
            : state.distoPalatal.bleeding,
        },
        palatal: {
          ...state.palatal,
          bleeding: state.palatal.palatal
            ? !state.palatal.bleeding
            : state.palatal.bleeding,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          bleeding: state.mesioPalatal.mesioPalatal
            ? !state.mesioPalatal.bleeding
            : state.mesioPalatal.bleeding,
        },
        distoBuccal: {
          ...state.distoBuccal,
          bleeding: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.bleeding
            : state.distoBuccal.bleeding,
        },
        buccal: {
          ...state.buccal,
          bleeding: state.buccal.buccal
            ? !state.buccal.bleeding
            : state.buccal.bleeding,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          bleeding: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.bleeding
            : state.mesioBuccal.bleeding,
        },
      };
    case PLAQUE + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          plaque: state.distoPalatal.distoPalatal
            ? !state.distoPalatal.plaque
            : state.distoPalatal.plaque,
        },
        palatal: {
          ...state.palatal,
          plaque: state.palatal.palatal
            ? !state.palatal.plaque
            : state.palatal.plaque,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          plaque: state.mesioPalatal.mesioPalatal
            ? !state.mesioPalatal.plaque
            : state.mesioPalatal.plaque,
        },
        distoBuccal: {
          ...state.distoBuccal,
          plaque: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.plaque
            : state.distoBuccal.plaque,
        },
        buccal: {
          ...state.buccal,
          plaque: state.buccal.buccal
            ? !state.buccal.plaque
            : state.buccal.plaque,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          plaque: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.plaque
            : state.mesioBuccal.plaque,
        },
      };
    case PUS + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          pus: state.distoPalatal.distoPalatal
            ? !state.distoPalatal.pus
            : state.distoPalatal.pus,
        },
        palatal: {
          ...state.palatal,
          pus: state.palatal.palatal ? !state.palatal.pus : state.palatal.pus,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          pus: state.mesioPalatal.mesioPalatal
            ? !state.mesioPalatal.pus
            : state.mesioPalatal.pus,
        },
        distoBuccal: {
          ...state.distoBuccal,
          pus: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.pus
            : state.distoBuccal.pus,
        },
        buccal: {
          ...state.buccal,
          pus: state.buccal.buccal ? !state.buccal.pus : state.buccal.pus,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          pus: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.pus
            : state.mesioBuccal.pus,
        },
      };
    case TARTAR + 22:
      return {
        ...state,
        distoPalatal: {
          ...state.distoPalatal,
          tartar: state.distoPalatal.distoPalatal
            ? !state.distoPalatal.tartar
            : state.distoPalatal.tartar,
        },
        palatal: {
          ...state.palatal,
          tartar: state.palatal.palatal
            ? !state.palatal.tartar
            : state.palatal.tartar,
        },
        mesioPalatal: {
          ...state.mesioPalatal,
          tartar: state.mesioPalatal.mesioPalatal
            ? !state.mesioPalatal.tartar
            : state.mesioPalatal.tartar,
        },
        distoBuccal: {
          ...state.distoBuccal,
          tartar: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.tartar
            : state.distoBuccal.tartar,
        },
        buccal: {
          ...state.buccal,
          tartar: state.buccal.buccal
            ? !state.buccal.tartar
            : state.buccal.tartar,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          tartar: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.tartar
            : state.mesioBuccal.tartar,
        },
      };
    default:
      return state;
  }
};

export default parodontal22;
