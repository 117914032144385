const InputLabel = ({
  label,
  type = 'text',
  name,
  value,
  required = true,
  onChange,
  placeholder,
  personnalisation,
  personnalisationLabel,
  disabled = false,
  min,
  max = 80,
  subText,
  accepted,
  handleAcceptChange,
  className,
  error,
}) => {
  const width = {
    'w-full': '100%',
    'w-15': '15%',
    'w-17': '17%',
    'w-18': '18%',
    'w-19': '19%',
    'w-22': '22%',
    'w-32': '32%',
    'w-40': '40%',
    'w-70': '70%',
    'w-50': '49%',
    'w-80': '80%',
    'w-85': '85%',
    'w-30': '29%',
    'w-65': '65%',
  };
  return (
    <div
      className={`inputLabel ${className ? className : ''}`}
      style={{ width: width[personnalisation], position: 'relative' }}
    >
      <div className='d-flex'>
        {subText && (
          <input
            type='checkbox'
            checked={accepted}
            onChange={() => handleAcceptChange(name)}
          />
        )}
        <label className={`inputLabel--label ${personnalisationLabel}`}>
          {label}
        </label>
      </div>
      <input
        className={`inputLabel--input ${subText ? 'inputLabel--input-subText-container' : ''
          }`}
        type={type}
        name={name}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={type === 'date' ? 'dd-mm-yyyy' : placeholder}
        disabled={disabled}
        minLength={min}
        maxLength={max}
        style={{ border: error ? '1px solid red' : '' }}
      />
    
      {!!subText && (
        <div className='inputLabel--subText'>
          <p>{subText}</p>
        </div>
      )}
    </div>
  );
};

export default InputLabel;
