export const Textarea = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  required,
  margin,
}) => {
  return (
    <div className='textarea' style={{ margin }}>
      <label className='textarea--label'>{label}</label>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};
