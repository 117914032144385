import { ADD_PROJECT_MODAL } from "./action";

const initState = {
  showAddProject: false,
};

const modals = (state = initState, action) => {
  switch (action.type) {
    case ADD_PROJECT_MODAL:
      return {
        ...state,
        showAddProject: !state.showAddProject,
      };
    default:
      return state;
  }
};
export default modals;
