import axios from 'axios';
import { BASE_URI } from '../app.config';

export const uploadFile = async (
  file,
  setIsLoading = () => {},
  setIsError = () => {}
) => {
  setIsLoading(true);
  const formData = new FormData();
  formData.append('file', file);
  const resImg = await axios.post(`${BASE_URI}file/add`, formData);
  if (resImg.status === 200) {
    setIsLoading(false);
  } else {
    setIsLoading(false);
    setIsError(true);
  }
  setIsLoading(false);
  return resImg?.data?.filename;
};

export const updloadAnotherFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const resImg = await axios.post(`${BASE_URI}file/add`, formData);

  return resImg.data.filename;
};
