import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BASE_URI } from '../../../assets/app.config';
import { helperAtobToken } from '../../../assets/helpers/helperAtobToken';
import Oclock from '../../../assets/images/oclock.png';

const CheckSubcription = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.user);

  const handleLogOut = async () => {
    const user = helperAtobToken('md_token', 'res');
    await axios.post(`${BASE_URI}user/logout/${user.id}`);
    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
  };
  useEffect(() => {
    if (!!user?.active) {
      return history.push('/dashboard');
    }
  }, [user?.active, history]);
  return (
    <div className='checkSubcription'>
      <div className='checkSubcription--container'>
        <img src={Oclock} alt='oclock' />
        <p>
          Votre abonnement est épuisé ou votre compte a été désactivé, veuillez
          nous contacter pour en savoir plus.
        </p>
        <button onClick={handleLogOut}>Me deconnecter</button>
      </div>
    </div>
  );
};

export default CheckSubcription;
