import {
  ENDOD,
  POSITIVE,
  LIMITED,
  UNPLEASANT,
  PAINFULSTIMULUS,
  PERSISTENTPAIN,
  UNCERTAIN,
  NEGATIVE,
  NOTAPPLICABLE,
  EXISTINGROOTCANAL,
  TREATMENTALREADY,
  PAINLESS,
  UNPLEASANTCOLDHEAT,
  PAINFUL,
  ELECTRIC,
  RESET,
} from "../actions";

const initState = {
  endod: null,
  cold: {
    positive: {
      positive: false,
      limited: false,
      unpleasant: false,
      painfulStimulus: false,
      persistentPain: false,
    },
    uncertain: false,
    negative: false,
    notApplicable: {
      notApplicable: false,
      existingRootCanalTreatment: false,
      treatmentAlreadyStarted: false,
    },
  },
  percussion: {
    painless: false,
    unpleasant: false,
    painful: false,
  },
  palpation: {
    painless: false,
    unpleasant: false,
    painful: false,
  },
  heat: {
    positive: {
      positive: false,
      limited: false,
      unpleasant: false,
      painfulStimulus: false,
      persistentPain: false,
    },
    uncertain: false,
    negative: false,
    notApplicable: {
      notApplicable: false,
      existingRootCanalTreatment: false,
      treatmentAlreadyStarted: false,
    },
  },
  electric: null,
};

const Endodontology34 = (state = initState, action) => {
  switch (action.type) {
    case RESET + 34:
      return { ...initState, load: true };
    case ENDOD + 34:
      return {
        ...state,
        endod: action.payload === state.endod ? "" : action.payload,
      };
    case ELECTRIC + 34:
      return {
        ...state,
        electric: state.endod === "electric" ? action.payload : state.electric,
      };
    case POSITIVE + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              positive: !state.cold.positive.positive,
              limited: false,
              unpleasant: false,
              painfulStimulus: false,
              persistentPain: false,
            },
            uncertain: false,
            negative: false,
            notApplicable: {
              ...state.cold.notApplicable,
              notApplicable: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              positive: !state.heat.positive.positive,
              limited: false,
              unpleasant: false,
              painfulStimulus: false,
              persistentPain: false,
            },
            uncertain: false,
            negative: false,
            notApplicable: {
              ...state.heat.notApplicable,
              notApplicable: false,
            },
          },
        };
      }

    case LIMITED + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              limited: state.cold.positive.positive
                ? !state.cold.positive.limited
                : state.cold.positive.limited,
              unpleasant: false,
              painfulStimulus: false,
              persistentPain: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              limited: state.heat.positive.positive
                ? !state.heat.positive.limited
                : state.heat.positive.limited,
              unpleasant: false,
              painfulStimulus: false,
              persistentPain: false,
            },
          },
        };
      }

    case UNPLEASANTCOLDHEAT + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              unpleasant: state.cold.positive.positive
                ? !state.cold.positive.unpleasant
                : state.cold.positive.unpleasant,
              limited: false,
              painfulStimulus: false,
              persistentPain: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              unpleasant: state.heat.positive.positive
                ? !state.heat.positive.unpleasant
                : !state.heat.positive.unpleasant,
              limited: false,
              painfulStimulus: false,
              persistentPain: false,
            },
          },
        };
      }

    case PAINFULSTIMULUS + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              painfulStimulus: state.cold.positive.positive
                ? !state.cold.positive.painfulStimulus
                : state.cold.positive.painfulStimulus,
              limited: false,
              unpleasant: false,
              persistentPain: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              painfulStimulus: state.heat.positive.positive
                ? !state.heat.positive.painfulStimulus
                : state.heat.positive.painfulStimulus,
              limited: false,
              unpleasant: false,
              persistentPain: false,
            },
          },
        };
      }

    case PERSISTENTPAIN + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              persistentPain: state.cold.positive.positive
                ? !state.cold.positive.persistentPain
                : state.cold.positive.persistentPain,
              limited: false,
              unpleasant: false,
              painfulStimulus: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              persistentPain: state.heat.positive.positive
                ? !state.heat.positive.persistentPain
                : state.heat.positive.persistentPain,
              limited: false,
              unpleasant: false,
              painfulStimulus: false,
            },
          },
        };
      }

    case UNCERTAIN + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              positive: false,
            },
            uncertain: !state.cold.uncertain,
            negative: false,
            notApplicable: {
              ...state.cold.notApplicable,
              notApplicable: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              positive: false,
            },
            uncertain: !state.heat.uncertain,
            negative: false,
            notApplicable: {
              ...state.heat.notApplicable,
              notApplicable: false,
            },
          },
        };
      }

    case NEGATIVE + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              positive: false,
            },
            negative: !state.cold.negative,
            uncertain: false,
            notApplicable: {
              ...state.cold.notApplicable,
              notApplicable: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              positive: false,
            },
            negative: !state.heat.negative,
            uncertain: false,
            notApplicable: {
              ...state.heat.notApplicable,
              notApplicable: false,
            },
          },
        };
      }

    case NOTAPPLICABLE + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            positive: {
              ...state.cold.positive,
              positive: false,
            },
            uncertain: false,
            negative: false,
            notApplicable: {
              ...state.cold.notApplicable,
              notApplicable: !state.cold.notApplicable.notApplicable,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            positive: {
              ...state.heat.positive,
              positive: false,
            },
            uncertain: false,
            negative: false,
            notApplicable: {
              ...state.heat.notApplicable,
              notApplicable: !state.heat.notApplicable.notApplicable,
            },
          },
        };
      }

    case EXISTINGROOTCANAL + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            notApplicable: {
              ...state.cold.notApplicable,
              existingRootCanalTreatment: state.cold.notApplicable.notApplicable
                ? !state.cold.notApplicable.existingRootCanalTreatment
                : state.cold.notApplicable.existingRootCanalTreatment,
              treatmentAlreadyStarted: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            notApplicable: {
              ...state.heat.notApplicable,
              existingRootCanalTreatment: state.heat.notApplicable.notApplicable
                ? !state.heat.notApplicable.existingRootCanalTreatment
                : state.heat.notApplicable.existingRootCanalTreatment,
              treatmentAlreadyStarted: false,
            },
          },
        };
      }

    case TREATMENTALREADY + 34:
      if (state.endod === "cold") {
        return {
          ...state,
          cold: {
            ...state.cold,
            notApplicable: {
              ...state.cold.notApplicable,
              treatmentAlreadyStarted: state.cold.notApplicable.notApplicable
                ? !state.cold.notApplicable.treatmentAlreadyStarted
                : state.cold.notApplicable.treatmentAlreadyStarted,
              existingRootCanalTreatment: false,
            },
          },
        };
      } else {
        return {
          ...state,
          heat: {
            ...state.heat,
            notApplicable: {
              ...state.heat.notApplicable,
              treatmentAlreadyStarted: state.heat.notApplicable.notApplicable
                ? !state.heat.notApplicable.treatmentAlreadyStarted
                : state.heat.notApplicable.treatmentAlreadyStarted,
              existingRootCanalTreatment: false,
            },
          },
        };
      }

    case PAINLESS + 34:
      return {
        ...state,
        percussion: {
          ...state.percussion,
          painless:
            state.endod === "percussion"
              ? !state.percussion.painless
              : state.percussion.painless,
          unpleasant:
            state.endod === "palpation" ? state.percussion.unpleasant : false,
          painful:
            state.endod === "palpation" ? state.percussion.painful : false,
        },
        palpation: {
          ...state.palpation,
          painless:
            state.endod === "palpation"
              ? !state.palpation.painless
              : state.palpation.painless,
          unpleasant:
            state.endod === "percussion" ? state.palpation.unpleasant : false,
          painful:
            state.endod === "percussion" ? state.palpation.painful : false,
        },
      };
    case UNPLEASANT + 34:
      return {
        ...state,
        percussion: {
          ...state.percussion,
          unpleasant:
            state.endod === "percussion"
              ? !state.percussion.unpleasant
              : state.percussion.unpleasant,
          painless:
            state.endod === "palpation" ? state.percussion.painless : false,
          painful:
            state.endod === "palpation" ? state.percussion.painful : false,
        },
        palpation: {
          ...state.palpation,
          unpleasant:
            state.endod === "palpation"
              ? !state.palpation.unpleasant
              : state.palpation.unpleasant,
          painless:
            state.endod === "percussion" ? state.palpation.painless : false,
          painful:
            state.endod === "percussion" ? state.palpation.painful : false,
        },
      };
    case PAINFUL + 34:
      return {
        ...state,
        percussion: {
          ...state.percussion,
          painful:
            state.endod === "percussion"
              ? !state.percussion.painful
              : state.percussion.painful,
          painless:
            state.endod === "palpation" ? state.percussion.painless : false,
          unpleasant:
            state.endod === "palpation" ? state.percussion.unpleasant : false,
        },
        palpation: {
          ...state.palpation,
          painful:
            state.endod === "palpation"
              ? !state.palpation.painful
              : state.palpation.painful,
          painless:
            state.endod === "percussion" ? state.palpation.painless : false,
          unpleasant:
            state.endod === "percussion" ? state.palpation.unpleasant : false,
        },
      };
    default:
      return state;
  }
};

export default Endodontology34;
