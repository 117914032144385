import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AddProjectModal } from './AddProject';

export const ModalsRender = () => {
  //
  const modals = useSelector((s) => s.modals);

  return <Fragment>{modals?.showAddProject && <AddProjectModal />}</Fragment>;
};
