import axios from "axios";
import { BASE_URI } from "../../assets/app.config";

export const LOAD_SICKS = "LOAD_SICKS";
export const loadSicks = () => {
  return {
    type: LOAD_SICKS,
  };
};

export const LOAD_SICKS_SUCCESS = "LOAD_SICKS_SUCCESS";
export const loadSicksSuccess = (sicks) => {
  return {
    type: LOAD_SICKS_SUCCESS,
    payload: sicks,
  };
};

export const LOAD_SICKS_ERROR = "LOAD_SICKS_ERROR";
export const loadSicksError = (error) => {
  return {
    type: LOAD_SICKS_ERROR,
    payload: error,
  };
};

export const fetchSicks = (id) => {
  return (dispatch) => {
    dispatch(loadSicks());
    axios
      .get(`${BASE_URI}mouth/${id}`)
      .then((res) => {
        dispatch(loadSicksSuccess(res?.data?.generalMedecine));
      })
      .catch((err) => {
        dispatch(loadSicksSuccess(err));
      });
  };
};

export const SELECTED_SICKS = "SELECTED_SICKS";
export const setSelectedSicks = (sicks) => {
  return {
    type: SELECTED_SICKS,
    payload: sicks,
  };
};
