export const darkBlue = '#1A3D50';
export const ligthBlue = '#2174A1';

//init data
export const initImgManage = {
  scale: 1,
  translation: { x: 0, y: 0 },
  rotation: 0,
  brightness: {
    property: 'brightness',
    value: 100,
    unit: '%',
  },
  contrast: {
    property: 'contrast',
    value: 100,
    unit: '%',
  },
  invert: {
    property: 'invert',
    value: 0,
    unit: '%',
  },
};
export const initImgSelected = { img: null, index: 1 };
export const initImages = {
  img1: null,
  img2: null,
  img3: null,
  img4: null,
};

export const initOtherSpecialist = {
  speciality: '',
  fullname: '',
  locality: '',
  phone: '',
};

export const initPatient = {
  folderNum: '',
  civility: 'Mr',
  lastname: '',
  firstname: '',
  matricule: '',
  dateOfBirth: '',
  healthInsurance: '',
  mutualInsurance: '',
  mobilePhone: '',
  fax: '',
  phoneCodeCountry: '',
  faxCodeCountry: '',
  email: '',
  number: '',
  street: '',
  complementary: '',
  zip: '',
  city: '',
  country: 'Luxembourg',
  freeText: '',
  doctor: '',
  otherSpecialist1: initOtherSpecialist,
  otherSpecialist2: initOtherSpecialist,
  otherSpecialist3: initOtherSpecialist,
  otherSpecialist4: initOtherSpecialist,
  principalInsured : {
    name: '',
    matricule: '',
    address: '',
    insured: '',
  },
};

export const countryOptions = [
  { countryCode: 'DZ', value: '213', label: '(+213) Algérie' },
  { countryCode: 'AD', value: '376', label: '(+376) Andorre' },
  { countryCode: 'AO', value: '244', label: '(+244) Angola' },
  { countryCode: 'AI', value: '1264', label: '(+1264) Anguilla' },
  { countryCode: 'AG', value: '1268', label: '(+1268) Antigua-et-Barbuda' },
  { countryCode: 'AR', value: '54', label: '(+54) Argentine' },
  { countryCode: 'AM', value: '374', label: '(+374) Arménie' },
  { countryCode: 'AW', value: '297', label: '(+297) Aruba' },
  { countryCode: 'AU', value: '61', label: '(+61) Australie' },
  { countryCode: 'AT', value: '43', label: '(+43) Autriche' },
  { countryCode: 'AZ', value: '994', label: '(+994) Azerbaïdjan' },
  { countryCode: 'BS', value: '1242', label: '(+1242) Bahamas' },
  { countryCode: 'BH', value: '973', label: '(+973) Bahreïn' },
  { countryCode: 'BD', value: '880', label: '(+880) Bangladesh' },
  { countryCode: 'BB', value: '1246', label: '(+1246) Barbade' },
  { countryCode: 'BY', value: '375', label: '(+375) Bélarus' },
  { countryCode: 'BE', value: '32', label: '(+32) Belgique' },
  { countryCode: 'BZ', value: '501', label: '(+501) Belize' },
  { countryCode: 'BJ', value: '229', label: '(+229) Bénin' },
  { countryCode: 'BM', value: '1441', label: '(+1441) Bermudes' },
  { countryCode: 'BT', value: '975', label: '(+975) Bhoutan' },
  { countryCode: 'BO', value: '591', label: '(+591) Bolivie' },
  { countryCode: 'BA', value: '387', label: '(+387) Bosnie-Herzégovine' },
  { countryCode: 'BW', value: '267', label: '(+267) Botswana' },
  { countryCode: 'BR', value: '55', label: '(+55) Brésil' },
  { countryCode: 'BN', value: '673', label: '(+673) Brunei' },
  { countryCode: 'BG', value: '359', label: '(+359) Bulgaria' },
  { countryCode: 'BF', value: '226', label: '(+226) Burkina Faso' },
  { countryCode: 'BI', value: '257', label: '(+257) Burundi' },
  { countryCode: 'KH', value: '855', label: '(+855) Cambodia' },
  { countryCode: 'CM', value: '237', label: '(+237) Cameroon' },
  { countryCode: 'CA', value: '1', label: '(+1) Canada' },
  { countryCode: 'CV', value: '238', label: '(+238) Cape Verde Islands' },
  { countryCode: 'KY', value: '1345', label: '(+1345) Cayman Islands' },
  { countryCode: 'CF', value: '236', label: '(+236) Central African Republic' },
  { countryCode: 'CL', value: '56', label: '(+56) Chile' },
  { countryCode: 'CN', value: '86', label: '(+86) China' },
  { countryCode: 'CO', value: '57', label: '(+57) Colombia' },
  { countryCode: 'KM', value: '269', label: '(+269) Comoros' },
  { countryCode: 'CG', value: '242', label: '(+242) Congo' },
  { countryCode: 'CK', value: '682', label: '(+682) Cook Islands' },
  { countryCode: 'CR', value: '506', label: '(+506) Costa Rica' },
  { countryCode: 'HR', value: '385', label: '(+385) Croatia' },
  { countryCode: 'CU', value: '53', label: '(+53) Cuba' },
  { countryCode: 'CY', value: '90392', label: '(+90392) Cyprus North' },
  { countryCode: 'CY', value: '357', label: '(+357) Cyprus South' },
  { countryCode: 'CZ', value: '42', label: '(+42) Czech Republic' },
  { countryCode: 'DK', value: '45', label: '(+45) Denmark' },
  { countryCode: 'DJ', value: '253', label: '(+253) Djibouti' },
  { countryCode: 'DM', value: '1809', label: '(+1809) Dominica' },
  { countryCode: 'DO', value: '1809', label: '(+1809) Dominican Republic' },
  { countryCode: 'EC', value: '593', label: '(+593) Ecuador' },
  { countryCode: 'EG', value: '20', label: '(+20) Egypt' },
  { countryCode: 'SV', value: '503', label: '(+503) El Salvador' },
  { countryCode: 'GQ', value: '240', label: '(+240) Equatorial Guinea' },
  { countryCode: 'ER', value: '291', label: '(+291) Eritrea' },
  { countryCode: 'EE', value: '372', label: '(+372) Estonia' },
  { countryCode: 'ET', value: '251', label: '(+251) Ethiopia' },
  { countryCode: 'FK', value: '500', label: '(+500) Falkland Islands' },
  { countryCode: 'FO', value: '298', label: '(+298) Faroe Islands' },
  { countryCode: 'FJ', value: '679', label: '(+679) Fiji' },
  { countryCode: 'FI', value: '358', label: '(+358) Finland' },
  { countryCode: 'FR', value: '33', label: '(+33) France' },
  { countryCode: 'GF', value: '594', label: '(+594) French Guiana' },
  { countryCode: 'PF', value: '689', label: '(+689) French Polynesia' },
  { countryCode: 'GA', value: '241', label: '(+241) Gabon' },
  { countryCode: 'GM', value: '220', label: '(+220) Gambia' },
  { countryCode: 'GE', value: '995', label: '(+995) Georgia' },
  { countryCode: 'DE', value: '49', label: '(+49) Germany' },
  { countryCode: 'GH', value: '233', label: '(+233) Ghana' },
  { countryCode: 'GI', value: '350', label: '(+350) Gibraltar' },
  { countryCode: 'GR', value: '30', label: '(+30) Greece' },
  { countryCode: 'GL', value: '299', label: '(+299) Greenland' },
  { countryCode: 'GD', value: '1473', label: '(+1473) Grenada' },
  { countryCode: 'GP', value: '590', label: '(+590) Guadeloupe' },
  { countryCode: 'GU', value: '671', label: '(+671) Guam' },
  { countryCode: 'GT', value: '502', label: '(+502) Guatemala' },
  { countryCode: 'GN', value: '224', label: '(+224) Guinea' },
  { countryCode: 'GW', value: '245', label: '(+245) Guinea - Bissau' },
  { countryCode: 'GY', value: '592', label: '(+592) Guyana' },
  { countryCode: 'HT', value: '509', label: '(+509) Haiti' },
  { countryCode: 'HN', value: '504', label: '(+504) Honduras' },
  { countryCode: 'HK', value: '852', label: '(+852) Hong Kong' },
  { countryCode: 'HU', value: '36', label: '(+36) Hungary' },
  { countryCode: 'IS', value: '354', label: '(+354) Iceland' },
  { countryCode: 'IN', value: '91', label: '(+91) India' },
  { countryCode: 'ID', value: '62', label: '(+62) Indonesia' },
  { countryCode: 'IR', value: '98', label: '(+98) Iran' },
  { countryCode: 'IQ', value: '964', label: '(+964) Iraq' },
  { countryCode: 'IE', value: '353', label: '(+353) Ireland' },
  { countryCode: 'IL', value: '972', label: '(+972) Israel' },
  { countryCode: 'IT', value: '39', label: '(+39) Italy' },
  { countryCode: 'JM', value: '1876', label: '(+1876) Jamaica' },
  { countryCode: 'JP', value: '81', label: '(+81) Japan' },
  { countryCode: 'JO', value: '962', label: '(+962) Jordan' },
  { countryCode: 'KZ', value: '7', label: '(+7) Kazakhstan' },
  { countryCode: 'KE', value: '254', label: '(+254) Kenya' },
  { countryCode: 'KI', value: '686', label: '(+686) Kiribati' },
  { countryCode: 'KP', value: '850', label: '(+850) Korea North' },
  { countryCode: 'KR', value: '82', label: '(+82) Korea South' },
  { countryCode: 'KW', value: '965', label: '(+965) Kuwait' },
  { countryCode: 'KG', value: '996', label: '(+996) Kyrgyzstan' },
  { countryCode: 'GE', value: '995', label: '(+995) Georgia' },
  { countryCode: 'DE', value: '49', label: '(+49) Germany' },
  { countryCode: 'GH', value: '233', label: '(+233) Ghana' },
  { countryCode: 'GI', value: '350', label: '(+350) Gibraltar' },
  { countryCode: 'GR', value: '30', label: '(+30) Greece' },
  { countryCode: 'GL', value: '299', label: '(+299) Greenland' },
  { countryCode: 'GD', value: '1473', label: '(+1473) Grenada' },
  { countryCode: 'GP', value: '590', label: '(+590) Guadeloupe' },
  { countryCode: 'GU', value: '671', label: '(+671) Guam' },
  { countryCode: 'GT', value: '502', label: '(+502) Guatemala' },
  { countryCode: 'GN', value: '224', label: '(+224) Guinea' },
  { countryCode: 'GW', value: '245', label: '(+245) Guinea - Bissau' },
  { countryCode: 'GY', value: '592', label: '(+592) Guyana' },
  { countryCode: 'HT', value: '509', label: '(+509) Haiti' },
  { countryCode: 'HN', value: '504', label: '(+504) Honduras' },
  { countryCode: 'HK', value: '852', label: '(+852) Hong Kong' },
  { countryCode: 'HU', value: '36', label: '(+36) Hungary' },

  { countryCode: 'IS', value: '354', label: '(+354) Iceland' },
  { countryCode: 'IN', value: '91', label: '(+91) India' },
  { countryCode: 'ID', value: '62', label: '(+62) Indonesia' },
  { countryCode: 'IR', value: '98', label: '(+98) Iran' },
  { countryCode: 'IQ', value: '964', label: '(+964) Iraq' },
  { countryCode: 'IE', value: '353', label: '(+353) Ireland' },
  { countryCode: 'IL', value: '972', label: '(+972) Israel' },
  { countryCode: 'IT', value: '39', label: '(+39) Italy' },
  { countryCode: 'JM', value: '1876', label: '(+1876) Jamaica' },
  { countryCode: 'JP', value: '81', label: '(+81) Japan' },
  { countryCode: 'JO', value: '962', label: '(+962) Jordan' },
  { countryCode: 'KZ', value: '7', label: '(+7) Kazakhstan' },
  { countryCode: 'KE', value: '254', label: '(+254) Kenya' },
  { countryCode: 'KI', value: '686', label: '(+686) Kiribati' },
  { countryCode: 'KP', value: '850', label: '(+850) Korea North' },
  { countryCode: 'KR', value: '82', label: '(+82) Korea South' },
  { countryCode: 'KW', value: '965', label: '(+965) Kuwait' },
  { countryCode: 'KG', value: '996', label: '(+996) Kyrgyzstan' },
  { countryCode: 'LA', value: '856', label: '(+856) Laos' },
  { countryCode: 'LV', value: '371', label: '(+371) Latvia' },
  { countryCode: 'LB', value: '961', label: '(+961) Lebanon' },
  { countryCode: 'LS', value: '266', label: '(+266) Lesotho' },
  { countryCode: 'LR', value: '231', label: '(+231) Liberia' },
  { countryCode: 'LY', value: '218', label: '(+218) Libya' },
  { countryCode: 'LI', value: '417', label: '(+417) Liechtenstein' },
  { countryCode: 'LT', value: '370', label: '(+370) Lithuania' },
  { countryCode: 'LU', value: '352', label: '(+352) Luxembourg' },
  { countryCode: 'MO', value: '853', label: '(+853) Macao' },
  { countryCode: 'MK', value: '389', label: '(+389) Macedonia' },
  { countryCode: 'MG', value: '261', label: '(+261) Madagascar' },
  { countryCode: 'MW', value: '265', label: '(+265) Malawi' },
  { countryCode: 'MY', value: '60', label: '(+60) Malaysia' },
  { countryCode: 'MV', value: '960', label: '(+960) Maldives' },
  { countryCode: 'ML', value: '223', label: '(+223) Mali' },
  { countryCode: 'MT', value: '356', label: '(+356) Malta' },
  { countryCode: 'MH', value: '692', label: '(+692) Marshall Islands' },
  { countryCode: 'MQ', value: '596', label: '(+596) Martinique' },
  { countryCode: 'MR', value: '222', label: '(+222) Mauritania' },
  { countryCode: 'YT', value: '269', label: '(+269) Mayotte' },
  { countryCode: 'MX', value: '52', label: '(+52) Mexico' },
  { countryCode: 'FM', value: '691', label: '(+691) Micronesia' },
  { countryCode: 'MD', value: '373', label: '(+373) Moldova' },
  { countryCode: 'MC', value: '377', label: '(+377) Monaco' },
  { countryCode: 'MN', value: '976', label: '(+976) Mongolia' },
  { countryCode: 'MS', value: '1664', label: '(+1664) Montserrat' },
  { countryCode: 'MA', value: '212', label: '(+212) Morocco' },
  { countryCode: 'MZ', value: '258', label: '(+258) Mozambique' },
  { countryCode: 'MN', value: '95', label: '(+95) Myanmar' },
  { countryCode: 'NA', value: '264', label: '(+264) Namibia' },
  { countryCode: 'NR', value: '674', label: '(+674) Nauru' },
  { countryCode: 'NP', value: '977', label: '(+977) Nepal' },
  { countryCode: 'NL', value: '31', label: '(+31) Netherlands' },
  { countryCode: 'NC', value: '687', label: '(+687) New Caledonia' },
  { countryCode: 'NZ', value: '64', label: '(+64) New Zealand' },
  { countryCode: 'NI', value: '505', label: '(+505) Nicaragua' },
  { countryCode: 'NE', value: '227', label: '(+227) Niger' },
  { countryCode: 'NG', value: '234', label: '(+234) Nigeria' },
  { countryCode: 'NU', value: '683', label: '(+683) Niue' },
  { countryCode: 'NF', value: '672', label: '(+672) Norfolk Islands' },
  { countryCode: 'NP', value: '670', label: '(+670) Northern Marianas' },
  { countryCode: 'NO', value: '47', label: '(+47) Norway' },
  { countryCode: 'OM', value: '968', label: '(+968) Oman' },
  { countryCode: 'PW', value: '680', label: '(+680) Palau' },
  { countryCode: 'PA', value: '507', label: '(+507) Panama' },
  { countryCode: 'PG', value: '675', label: '(+675) Papua New Guinea' },
  { countryCode: 'PY', value: '595', label: '(+595) Paraguay' },
  { countryCode: 'PE', value: '51', label: '(+51) Peru' },
  { countryCode: 'PH', value: '63', label: '(+63) Philippines' },
  { countryCode: 'PL', value: '48', label: '(+48) Poland' },
  { countryCode: 'PT', value: '351', label: '(+351) Portugal' },
  { countryCode: 'PR', value: '1787', label: '(+1787) Puerto Rico' },
  { countryCode: 'QA', value: '974', label: '(+974) Qatar' },
  { countryCode: 'RE', value: '262', label: '(+262) Reunion' },
  { countryCode: 'RO', value: '40', label: '(+40) Romania' },
  { countryCode: 'RU', value: '7', label: '(+7) Russia' },
  { countryCode: 'RW', value: '250', label: '(+250) Rwanda' },
  { countryCode: 'SM', value: '378', label: '(+378) San Marino' },
  { countryCode: 'ST', value: '239', label: '(+239) Sao Tome & Principe' },
  { countryCode: 'SA', value: '966', label: '(+966) Saudi Arabia' },
  { countryCode: 'SN', value: '221', label: '(+221) Senegal' },
  { countryCode: 'CS', value: '381', label: '(+381) Serbia' },
  { countryCode: 'SC', value: '248', label: '(+248) Seychelles' },
  { countryCode: 'SL', value: '232', label: '(+232) Sierra Leone' },
  { countryCode: 'SG', value: '65', label: '(+65) Singapore' },
  { countryCode: 'SK', value: '421', label: '(+421) Slovak Republic' },
  { countryCode: 'SI', value: '386', label: '(+386) Slovenia' },
  { countryCode: 'SB', value: '677', label: '(+677) Solomon Islands' },
  { countryCode: 'SO', value: '252', label: '(+252) Somalia' },
  { countryCode: 'ZA', value: '27', label: '(+27) South Africa' },
  { countryCode: 'ES', value: '34', label: '(+34) Spain' },
  { countryCode: 'LK', value: '94', label: '(+94) Sri Lanka' },
  { countryCode: 'SH', value: '290', label: '(+290) St. Helena' },
  { countryCode: 'KN', value: '1869', label: '(+1869) St. Kitts' },
  { countryCode: 'SC', value: '1758', label: '(+1758) St. Lucia' },
  { countryCode: 'SD', value: '249', label: '(+249) Sudan' },
  { countryCode: 'SR', value: '597', label: '(+597) Suriname' },
  { countryCode: 'SZ', value: '268', label: '(+268) Swaziland' },
  { countryCode: 'SE', value: '46', label: '(+46) Sweden' },
  { countryCode: 'CH', value: '41', label: '(+41) Switzerland' },
  { countryCode: 'SI', value: '963', label: '(+963) Syria' },
  { countryCode: 'TW', value: '886', label: '(+886) Taiwan' },
  { countryCode: 'TJ', value: '7', label: '(+7) Tajikstan' },
  { countryCode: 'TH', value: '66', label: '(+66) Thailand' },
  { countryCode: 'TG', value: '228', label: '(+228) Togo' },
  { countryCode: 'TO', value: '676', label: '(+676) Tonga' },
  { countryCode: 'TT', value: '1868', label: '(+1868) Trinidad & Tobago' },
  { countryCode: 'TN', value: '216', label: '(+216) Tunisia' },
  { countryCode: 'TR', value: '90', label: '(+90) Turkey' },
  { countryCode: 'TM', value: '7', label: '(+7) Turkmenistan' },
  { countryCode: 'TM', value: '993', label: '(+993) Turkmenistan' },
  { countryCode: 'TC', value: '1649', label: '(+1649) Turks & Caicos Islands' },
  { countryCode: 'TV', value: '688', label: '(+688) Tuvalu' },
  { countryCode: 'UG', value: '256', label: '(+256) Uganda' },
  { countryCode: 'GB', value: '44', label: '(+44) UK' },
  { countryCode: 'UA', value: '380', label: '(+380) Ukraine' },
  { countryCode: 'AE', value: '971', label: '(+971) United Arab Emirates' },
  { countryCode: 'UY', value: '598', label: '(+598) Uruguay' },
  { countryCode: 'US', value: '1', label: '(+1) USA' },
  { countryCode: 'UZ', value: '7', label: '(+7) Uzbekistan' },
  { countryCode: 'VU', value: '678', label: '(+678) Vanuatu' },
  { countryCode: 'VA', value: '379', label: '(+379) Vatican City' },
  { countryCode: 'VE', value: '58', label: '(+58) Venezuela' },
  { countryCode: 'VN', value: '84', label: '(+84) Vietnam' },
  { countryCode: 'VG', value: '84', label: '(+1284) Virgin Islands - British' },
  { countryCode: 'VI', value: '84', label: '(+1340) Virgin Islands - US' },
  { countryCode: 'WF', value: '681', label: '(+681) Wallis & Futuna' },
  { countryCode: 'YE', value: '969', label: '(+969) Yemen (North)' },
  { countryCode: 'YE', value: '967', label: '(+967) Yemen (South)' },
  { countryCode: 'ZM', value: '260', label: '(+260) Zambia' },
  { countryCode: 'ZW', value: '263', label: '(+263) Zimbabwe' },
];
