import { TOGGLE_JAW } from "./actions";

const initialState = {
  jaws: {
    top: true,
    all: false,
    bottom: false,
  },
};

const helperToggle = (payload) => {
  if (payload === "top") {
    return { top: true, all: false, bottom: false };
  } else if (payload === "all") {
    return { top: false, all: true, bottom: false };
  } else if (payload === "bottom") {
    return { top: false, all: false, bottom: true };
  }
};

const jaw = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_JAW:
      return { ...state, jaws: helperToggle(action.payload) };
    default:
      return state;
  }
};

export default jaw;
