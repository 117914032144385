import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//REDUCERS
import theme from './theme';
import jaw from './jaw';
import user from './user';
import teeth11 from './teeth/11';
import teeth12 from './teeth/12';
import teeth13 from './teeth/13';
import teeth14 from './teeth/14';
import teeth15 from './teeth/15';
import teeth16 from './teeth/16';
import teeth17 from './teeth/17';
import teeth18 from './teeth/18';
import teeth21 from './teeth/21';
import teeth22 from './teeth/22';
import teeth23 from './teeth/23';
import teeth24 from './teeth/24';
import teeth25 from './teeth/25';
import teeth26 from './teeth/26';
import teeth27 from './teeth/27';
import teeth28 from './teeth/28';
import teeth31 from './teeth/31';
import teeth32 from './teeth/32';
import teeth33 from './teeth/33';
import teeth34 from './teeth/34';
import teeth35 from './teeth/35';
import teeth36 from './teeth/36';
import teeth37 from './teeth/37';
import teeth38 from './teeth/38';
import teeth41 from './teeth/41';
import teeth42 from './teeth/42';
import teeth43 from './teeth/43';
import teeth44 from './teeth/44';
import teeth45 from './teeth/45';
import teeth46 from './teeth/46';
import teeth47 from './teeth/47';
import teeth48 from './teeth/48';
import parodontal11 from './parodontal/11';
import parodontal12 from './parodontal/12';
import parodontal13 from './parodontal/13';
import parodontal14 from './parodontal/14';
import parodontal15 from './parodontal/15';
import parodontal16 from './parodontal/16';
import parodontal17 from './parodontal/17';
import parodontal18 from './parodontal/18';
import parodontal21 from './parodontal/21';
import parodontal22 from './parodontal/22';
import parodontal23 from './parodontal/23';
import parodontal24 from './parodontal/24';
import parodontal25 from './parodontal/25';
import parodontal26 from './parodontal/26';
import parodontal27 from './parodontal/27';
import parodontal28 from './parodontal/28';
import parodontal31 from './parodontal/31';
import parodontal32 from './parodontal/32';
import parodontal33 from './parodontal/33';
import parodontal34 from './parodontal/34';
import parodontal35 from './parodontal/35';
import parodontal36 from './parodontal/36';
import parodontal37 from './parodontal/37';
import parodontal38 from './parodontal/38';
import parodontal41 from './parodontal/41';
import parodontal42 from './parodontal/42';
import parodontal43 from './parodontal/43';
import parodontal44 from './parodontal/44';
import parodontal45 from './parodontal/45';
import parodontal46 from './parodontal/46';
import parodontal47 from './parodontal/47';
import parodontal48 from './parodontal/48';
import endodontology11 from './endodontology/11';
import endodontology12 from './endodontology/12';
import endodontology13 from './endodontology/13';
import endodontology14 from './endodontology/14';
import endodontology15 from './endodontology/15';
import endodontology16 from './endodontology/16';
import endodontology17 from './endodontology/17';
import endodontology18 from './endodontology/18';
import endodontology21 from './endodontology/21';
import endodontology22 from './endodontology/22';
import endodontology23 from './endodontology/23';
import endodontology24 from './endodontology/24';
import endodontology25 from './endodontology/25';
import endodontology26 from './endodontology/26';
import endodontology27 from './endodontology/27';
import endodontology28 from './endodontology/28';
import endodontology31 from './endodontology/31';
import endodontology32 from './endodontology/32';
import endodontology33 from './endodontology/33';
import endodontology34 from './endodontology/34';
import endodontology35 from './endodontology/35';
import endodontology36 from './endodontology/36';
import endodontology37 from './endodontology/37';
import endodontology38 from './endodontology/38';
import endodontology41 from './endodontology/41';
import endodontology42 from './endodontology/42';
import endodontology43 from './endodontology/43';
import endodontology44 from './endodontology/44';
import endodontology45 from './endodontology/45';
import endodontology46 from './endodontology/46';
import endodontology47 from './endodontology/47';
import endodontology48 from './endodontology/48';
import generalMedecine from './generalMedecine';
import teethOverview from './teethOverview';
import sendPdf from './sendPdf';
import modals from './modals';

const persistConf = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  theme: theme,
  jaw: jaw,
  user: user,
  teeth11: teeth11,
  teeth12: teeth12,
  teeth13: teeth13,
  teeth14: teeth14,
  teeth15: teeth15,
  teeth16: teeth16,
  teeth17: teeth17,
  teeth18: teeth18,
  teeth21: teeth21,
  teeth22: teeth22,
  teeth23: teeth23,
  teeth24: teeth24,
  teeth25: teeth25,
  teeth26: teeth26,
  teeth27: teeth27,
  teeth28: teeth28,
  teeth31: teeth31,
  teeth32: teeth32,
  teeth33: teeth33,
  teeth34: teeth34,
  teeth35: teeth35,
  teeth36: teeth36,
  teeth37: teeth37,
  teeth38: teeth38,
  teeth41: teeth41,
  teeth42: teeth42,
  teeth43: teeth43,
  teeth44: teeth44,
  teeth45: teeth45,
  teeth46: teeth46,
  teeth47: teeth47,
  teeth48: teeth48,
  parodontal11: parodontal11,
  parodontal12: parodontal12,
  parodontal13: parodontal13,
  parodontal14: parodontal14,
  parodontal15: parodontal15,
  parodontal16: parodontal16,
  parodontal17: parodontal17,
  parodontal18: parodontal18,
  parodontal21: parodontal21,
  parodontal22: parodontal22,
  parodontal23: parodontal23,
  parodontal24: parodontal24,
  parodontal25: parodontal25,
  parodontal26: parodontal26,
  parodontal27: parodontal27,
  parodontal28: parodontal28,
  parodontal31: parodontal31,
  parodontal32: parodontal32,
  parodontal33: parodontal33,
  parodontal34: parodontal34,
  parodontal35: parodontal35,
  parodontal36: parodontal36,
  parodontal37: parodontal37,
  parodontal38: parodontal38,
  parodontal41: parodontal41,
  parodontal42: parodontal42,
  parodontal43: parodontal43,
  parodontal44: parodontal44,
  parodontal45: parodontal45,
  parodontal46: parodontal46,
  parodontal47: parodontal47,
  parodontal48: parodontal48,
  endodontology11: endodontology11,
  endodontology12: endodontology12,
  endodontology13: endodontology13,
  endodontology14: endodontology14,
  endodontology15: endodontology15,
  endodontology16: endodontology16,
  endodontology17: endodontology17,
  endodontology18: endodontology18,
  endodontology21: endodontology21,
  endodontology22: endodontology22,
  endodontology23: endodontology23,
  endodontology24: endodontology24,
  endodontology25: endodontology25,
  endodontology26: endodontology26,
  endodontology27: endodontology27,
  endodontology28: endodontology28,
  endodontology31: endodontology31,
  endodontology32: endodontology32,
  endodontology33: endodontology33,
  endodontology34: endodontology34,
  endodontology35: endodontology35,
  endodontology36: endodontology36,
  endodontology37: endodontology37,
  endodontology38: endodontology38,
  endodontology41: endodontology41,
  endodontology42: endodontology42,
  endodontology43: endodontology43,
  endodontology44: endodontology44,
  endodontology45: endodontology45,
  endodontology46: endodontology46,
  endodontology47: endodontology47,
  endodontology48: endodontology48,
  generalMedecine: generalMedecine,
  teethOverview: teethOverview,
  sendPdf: sendPdf,
  modals: modals,
});

export const store = createStore(
  persistReducer(persistConf, reducers),
  applyMiddleware(thunk)
);
export const persistor = persistStore(store);
