import { useSelector } from 'react-redux';
import { darkBlue, ligthBlue } from '../assets/contants';

const withTheme = (WrappedComponend) => (props) => {
  const dark = useSelector((s) => s.theme.dark);

  const theme = {
    background: !dark ? '#FFF' : darkBlue,
    bgLightGrey: !dark ? '#F3F3F3' : darkBlue,
    text: !dark ? darkBlue : '#FFF',
    shadow: !dark ? '#1a3d5017' : '#FFFFFF4D',
    blueLight: ligthBlue,
  };

  return <WrappedComponend {...props} theme={theme} />;
};

export default withTheme;
