import {
  P_CARIE_MESIAL,
  P_CARIE_DISTAL,
  P_CARIE_PALATAL,
  P_CARIE_BUCCAL,
  P_CARIE_PALATAL_CERVICAL,
  P_CARIE_BUCCAL_CERVICAL,
  P_CARIE_SURFACE_PALATAL,
  P_CARIE_SURFACE_BUCCAL,
  P_CARIE_OCCLUSAL,
  P_CARIE_BOSSE_P,
  P_CARIE_CUSPIDE_B,
  P_FCH,
  P_FRH,
  P_FRV,
  P_APICAL,
  P_ABRASION_PALATAL,
  P_DG,
  P_DR,
  P_DY,
  P_EROSION_PALATAL,
  P_EROSION_BUCCAL,
  P_EROSION_SP,
  P_EROSION_SB,
  C_COURONNE,
  C_FACETTE_F,
  C_FACETTE_FB,
  C_FACETTE_FP,
  C_OBTURATION_M,
  C_OBTURATION_D,
  C_OBTURATION_P,
  C_OBTURATION_B,
  C_OBTURATION_PC,
  C_OBTURATION_BC,
  C_OBTURATION_SP,
  C_OBTURATION_SB,
  C_OBTURATION_MC4,
  C_OBTURATION_C4D,
  C_OBTURATION_FB,
  C_OBTURATION_FP,
  P_FCV,
  P_ABRASION_BUCCAL,
  C_OBTURATION_O,
  C_OBTURATION_CB,
  C_OBTURATION_BP,
  C_MATERIALS_COMPOSITE,
  C_MATERIALS_CERAMIC,
  C_MATERIALS_GOLD,
  C_MATERIALS_BASEMETAL,
  C_MATERIALS_PROVISIONAL,
  C_MATERIALS_AMALGAM,
  C_CARE,
  C_PARTIALCROWN_B,
  C_PARTIALCROWN_P,
  C_PARTIALCROWN_CB,
  C_PARTIALCROWN_BP,
  TOOTH,
  RESET,
  INIT,
  P_DENTINE,
  P_EMAIL,
  P_PRESENTOFCAVITY,
  P_ABSENCEOFCAVITY,
  P_PULP_DAMAGE,
  P_PULP_NO_AFFECTED,
  P_C1,
  P_C2,
  P_C3,
  P_C4,
  P_PROBLEM_DEVELOP,
  C_QUALITY_SUFFICIENT,
  C_QUALITY_UNCERTAIN,
  C_QUALITY_INSUFFICIENT,
  C_DETAIL_SURPLUS,
  C_DETAIL_FLUSH,
  C_DETAIL_DEFICIT,
  C_CROWNTYPE_SIMPLECROWN,
  C_CROWNTYPE_PILLAR,
  C_CROWNTYPE_PONTIC,
  C_TOOTHTYPE,
  C_IMPLANTTYPE_BL,
  C_IMPLANTTYPE_TL,
} from "../actions";

const initialState = {
  load: false,
  pathologies: {
    carie: {
      mesial: false,
      distal: false,
      palatal: false,
      buccal: false,
      palatalCervical: false,
      buccalCervical: false,
      surfacePalatal: false,
      surfaceBuccal: false,
      occlusal: false,
      bossePalatal: false,
      cuspideBuccal: false,
      dentine: {
        dentine: false,
        presenceOfCavity: false,
        absenceOfCavity: false,
        pulpDamage: false,
        pulpNotAffected: false,
        c1: false,
        c2: false,
        c3: false,
        c4: false,
      },
      email: {
        email: false,
        presenceOfCavity: false,
        absenceOfCavity: false,
        c1: false,
        c2: false,
        c3: false,
        c4: false,
      },
    },
    developmentProblem: false,
    fractureCoronaireHorizontal: false,
    fractureCoronaireVertical: false,
    fractureRadiculaireHorizontal: false,
    fractureRadiculaireVertical: false,
    apical: false,
    usureAbrasionPalatal: false,
    usureAbrasionBuccal: false,
    discolorationGrey: false,
    discolorationRed: false,
    discolorationYellow: false,
    erosion: {
      erosionPalatal: false,
      erosionBuccal: false,
      surfacePanatal: false,
      surfaceBuccal: false,
    },
  },
  cares: {
    care: null,
    couronne: false,
    facette: {
      facetteBuccal: false,
      facettePalatal: false,
      surfacePalatal: false,
      bossePalatal: false,
      mesial: false,
      distal: false,
    },
    onlay: {
      onlay: false,
      mesial: false,
      distal: false,
    },
    inlay: {
      mesial: false,
      distal: false,
    },
    partialCrown: {
      mesial: false,
      distal: false,
      cuspideBuccal: false,
      buccal: false,
      palatal: false,
      bossePalatal: false,
      occlusal: false,
    },
    obturation: {
      mesial: false,
      distal: false,
      palatal: false,
      buccal: false,
      palatalCervical: false,
      buccalCervical: false,
      surfacePalatal: false,
      surfaceBuccal: false,
      cuspideBuccal: false,
      mesialClasse4: false,
      classe4Distal: false,
      facetteBuccal: false,
      facettePalatal: false,
      occlusal: false,
      bossePalatal: false,
    },
    materials: {
      composite: false,
      ceramic: false,
      gold: false,
      baseMetal: false,
      provisional: false,
      amalgam: false,
    },
    quality: {
      sufficient: false,
      uncertain: false,
      insufficient: false,
    },
    detail: {
      surplus: false,
      flush: false,
      deficit: false,
    },
    crownType: {
      simplecrown: false,
      pillar: false,
    },
    implantType: {
      bonelevel: false,
      tissuelevel: false,
    },
  },
  tooth: {
    implant: false,
    pontic: false,
    missing: false,
    dental: false,
  },
};

const teeth24 = (state = initialState, action) => {
  switch (action.type) {
    //REQUEST
    case INIT + 24:
      return {
        ...state,
        ...action?.payload,
        load: true,
      };
    //RESET
    case RESET + 24:
      if (action.payload === "all") {
        return {
          ...state,
          pathologies: initialState.pathologies,
          cares: initialState.cares,
          tooth: initialState.tooth,
        };
      } else {
        return {
          ...state,
          pathologies:
            action.payload === "pathologies"
              ? initialState.pathologies
              : state.pathologies,
          cares: action.payload === "cares" ? initialState.cares : state.cares,
          tooth: action.payload === "cares" ? initialState.tooth : state.tooth,
        };
      }
    //PROBLEM_DEVELOP
    case P_PROBLEM_DEVELOP + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          developmentProblem: !state.pathologies.developmentProblem,
        },
      };
    //DENTINE & EMAIL
    case P_DENTINE + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              dentine: !state.pathologies.carie.dentine.dentine,
            },
            email: {
              ...state.pathologies.carie.email,
              email: false,
            },
          },
        },
      };
    case P_EMAIL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            email: {
              ...state.pathologies.carie.email,
              email: !state.pathologies.carie.email.email,
            },
            dentine: {
              ...state.pathologies.carie.dentine,
              dentine: false,
            },
          },
        },
      };

    case P_PRESENTOFCAVITY + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              presenceOfCavity: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.presenceOfCavity
                : false,
              absenceOfCavity: false,
            },
            email: {
              ...state.pathologies.carie.email,
              presenceOfCavity: state.pathologies.carie.email.email
                ? !state.pathologies.carie.email.presenceOfCavity
                : false,
              absenceOfCavity: false,
            },
          },
        },
      };
    case P_ABSENCEOFCAVITY + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              presenceOfCavity: false,
              absenceOfCavity: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.absenceOfCavity
                : false,
            },
            email: {
              ...state.pathologies.carie.email,
              presenceOfCavity: false,
              absenceOfCavity: state.pathologies.carie.email.email
                ? !state.pathologies.carie.email.absenceOfCavity
                : false,
            },
          },
        },
      };

    case P_PULP_DAMAGE + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              pulpDamage: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.pulpDamage
                : false,
              pulpNotAffected: false,
            },
          },
        },
      };
    case P_PULP_NO_AFFECTED + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              pulpDamage: false,
              pulpNotAffected: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.pulpNotAffected
                : false,
            },
          },
        },
      };
    case P_C1 + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: state.pathologies.carie.dentine.dentine && true,
              c2: false,
              c3: false,
              c4: false,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: state.pathologies.carie.email.email && true,
              c2: false,
              c3: false,
              c4: false,
            },
          },
        },
      };
    case P_C2 + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: false,
              c2: state.pathologies.carie.dentine.dentine && true,
              c3: false,
              c4: false,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: false,
              c2: state.pathologies.carie.email.email && true,
              c3: false,
              c4: false,
            },
          },
        },
      };
    case P_C3 + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: false,
              c2: false,
              c3: state.pathologies.carie.dentine.dentine && true,
              c4: false,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: false,
              c2: false,
              c3: state.pathologies.carie.email.email && true,
              c4: false,
            },
          },
        },
      };
    case P_C4 + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: false,
              c2: false,
              c3: false,
              c4: state.pathologies.carie.dentine.dentine && true,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: false,
              c2: false,
              c3: false,
              c4: state.pathologies.carie.email.email && true,
            },
          },
        },
      };
    //TOOTH TYPE
    case TOOTH + 24:
      return {
        ...state,
        tooth: {
          ...state.tooth,
          implant: action.payload === "implant" ? !state.tooth.implant : false,
          pontic: action.payload === "pontic" ? !state.tooth.pontic : false,
          missing: action.payload === "missing" ? !state.tooth.missing : false,
        },
      };
    //PATHOLOGIES
    case P_CARIE_MESIAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            mesial: !state.pathologies.carie.mesial,
          },
        },
      };
    case P_CARIE_DISTAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            distal: !state.pathologies.carie.distal,
          },
        },
      };
    case P_CARIE_PALATAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            palatal: !state.pathologies.carie.palatal,
          },
        },
      };
    case P_CARIE_BUCCAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            buccal: !state.pathologies.carie.buccal,
          },
        },
      };
    case P_CARIE_PALATAL_CERVICAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            palatalCervical: !state.pathologies.carie.palatalCervical,
          },
        },
      };
    case P_CARIE_BUCCAL_CERVICAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            buccalCervical: !state.pathologies.carie.buccalCervical,
          },
        },
      };
    case P_CARIE_SURFACE_PALATAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            surfacePalatal: !state.pathologies.carie.surfacePalatal,
          },
        },
      };
    case P_CARIE_SURFACE_BUCCAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            surfaceBuccal: !state.pathologies.carie.surfaceBuccal,
          },
        },
      };
    case P_CARIE_OCCLUSAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            occlusal:
              action.payload === "occlusal" &&
              !state.pathologies.carie.occlusal,
          },
        },
      };
    case P_CARIE_BOSSE_P + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            bossePalatal:
              action.payload === "bosspalatal" &&
              !state.pathologies.carie.bossePalatal,
          },
        },
      };
    case P_CARIE_CUSPIDE_B + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            cuspideBuccal:
              action.payload === "cuspidebuccal" &&
              !state.pathologies.carie.cuspideBuccal,
          },
        },
      };
    case P_FCV + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureCoronaireVertical:
            !state.pathologies.fractureCoronaireVertical,
          fractureCoronaireHorizontal: false,
        },
      };
    case P_FCH + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureCoronaireHorizontal:
            !state.pathologies.fractureCoronaireHorizontal,
          fractureCoronaireVertical: false,
        },
      };
    case P_FRH + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureRadiculaireHorizontal:
            !state.pathologies.fractureRadiculaireHorizontal,
        },
      };
    case P_FRV + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureRadiculaireVertical:
            !state.pathologies.fractureRadiculaireVertical,
        },
      };
    case P_APICAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          apical: action.payload === "yes" ? true : false,
        },
      };
    case P_ABRASION_PALATAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          usureAbrasionPalatal:
            action.payload === "abrasion" &&
            !state.pathologies.usureAbrasionPalatal,
        },
      };
    case P_ABRASION_BUCCAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          usureAbrasionBuccal:
            action.payload === "abrasion" &&
            !state.pathologies.usureAbrasionBuccal,
        },
      };
    case P_DG + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          discolorationGrey:
            action.payload === "grey" && !state.pathologies.discolorationGrey,
          discolorationRed: false,
          discolorationYellow: false,
        },
      };
    case P_DR + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          discolorationRed:
            action.payload === "red" && !state.pathologies.discolorationRed,
          discolorationGrey: false,
          discolorationYellow: false,
        },
      };
    case P_DY + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          discolorationYellow:
            action.payload === "yellow" &&
            !state.pathologies.discolorationYellow,
          discolorationGrey: false,
          discolorationRed: false,
        },
      };
    case P_EROSION_PALATAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            erosionPalatal:
              action.payload === "erosion" &&
              !state.pathologies.erosion.erosionPalatal,
          },
        },
      };
    case P_EROSION_BUCCAL + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            erosionBuccal:
              action.payload === "erosion" &&
              !state.pathologies.erosion.erosionBuccal,
          },
        },
      };
    case P_EROSION_SP + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            surfacePalatal: !state.pathologies.erosion.surfacePalatal,
          },
        },
      };
    case P_EROSION_SB + 24:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            surfaceBuccal: !state.pathologies.erosion.surfaceBuccal,
          },
        },
      };

    //CARES
    case C_CARE + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          care: action.payload,
          couronne: action.payload === "crown" && !state.cares.couronne,
          obturation: {
            ...initialState.cares.obturation,
            mesial:
              (action.payload === "inlay" ||
                action.payload === "partialcrown") &&
              true,
            distal:
              (action.payload === "inlay" ||
                action.payload === "partialcrown") &&
              true,
            occlusal:
              (action.payload === "inlay" ||
                action.payload === "partialcrown") &&
              true,
          },
          facette: {
            ...initialState.cares.facette,
            facetteBuccal: action.payload === "facet" && true,
            mesial: action.payload === "facet" && true,
            distal: action.payload === "facet" && true,
          },
          onlay: {
            ...initialState.cares.onlay,
            onlay: action.payload === "onlay" && !state.cares.onlay.onlay,
          },
          partialCrown: {
            ...initialState.cares.partialCrown,
            mesial: action.payload === "partialcrown" && true,
            distal: action.payload === "partialcrown" && true,
            buccal: action.payload === "partialcrown" && true,
            cuspideBuccal: action.payload === "partialcrown" && true,
            occlusal: action.payload === "partialcrown" && true,
          },
          materials: {
            ...initialState.cares.materials,
          },
        },
      };
    case C_COURONNE + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          couronne: !state.cares.couronne,
        },
      };
    case C_FACETTE_F + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          facette: {
            ...state.cares.facette,
            facette:
              state.cares.care === "facet" && !state.cares.facette.facette,
          },
        },
      };
    case C_FACETTE_FB + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          facette: {
            ...state.cares.facette,
            facetteBuccal:
              state.cares.care === "facet" &&
              !state.cares.facette.facetteBuccal,
            surfacePalatal: false,
            bossePalatal: false,
          },
        },
      };
    case C_FACETTE_FP + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          facette: {
            ...state.cares.facette,
            surfacePalatal:
              state.cares.care === "facet" &&
              !state.cares.facette.surfacePalatal,
            facetteBuccal: false,
            bossePalatal: !state.cares.facette.bossePalatal,
          },
        },
      };
    case C_OBTURATION_M + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            mesial:
              state.cares.care === "obturation" ||
              state.cares.care === "inlay" ||
              state.cares.care === "onlay"
                ? !state.cares.obturation.mesial
                : false,
          },
        },
      };
    case C_OBTURATION_D + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            distal:
              state.cares.care === "obturation" ||
              state.cares.care === "inlay" ||
              state.cares.care === "onlay"
                ? !state.cares.obturation.distal
                : false,
          },
        },
      };
    case C_OBTURATION_P + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            palatal:
              state.cares.care === "obturation" ||
              state.cares.care === "partialcrown"
                ? !state.cares.obturation.palatal
                : false,
          },
        },
      };
    case C_OBTURATION_B + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            buccal:
              state.cares.care === "obturation" ||
              state.cares.care === "partialcrown"
                ? !state.cares.obturation.buccal
                : false,
          },
        },
      };
    case C_OBTURATION_PC + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            palatalCervical:
              state.cares.care === "obturation"
                ? !state.cares.obturation.palatalCervical
                : false,
          },
        },
      };
    case C_OBTURATION_BC + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            buccalCervical:
              state.cares.care === "obturation"
                ? !state.cares.obturation.buccalCervical
                : false,
          },
        },
      };
    case C_OBTURATION_SP + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            surfacePalatal:
              state.cares.care === "obturation" || state.cares.care === "onlay"
                ? !state.cares.obturation.surfacePalatal
                : false,
          },
        },
      };
    case C_OBTURATION_SB + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            surfaceBuccal:
              state.cares.care === "obturation" || state.cares.care === "onlay"
                ? !state.cares.obturation.surfaceBuccal
                : false,
          },
        },
      };
    case C_OBTURATION_MC4 + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            mesialClasse4:
              state.cares.care === "obturation"
                ? !state.cares.obturation.mesialClasse4
                : false,
          },
        },
      };
    case C_OBTURATION_C4D + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            classe4Distal:
              state.cares.care === "obturation"
                ? !state.cares.obturation.classe4Distal
                : false,
          },
        },
      };
    case C_OBTURATION_FB + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            facetteBuccal:
              state.cares.care === "obturation"
                ? !state.cares.obturation.mesial
                : false,
          },
        },
      };
    case C_OBTURATION_FP + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            facettePalatal:
              state.cares.care === "obturation"
                ? !state.cares.obturation.facettePalatal
                : false,
          },
        },
      };
    case C_OBTURATION_O + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            occlusal:
              (state.cares.care === "obturation" ||
                state.cares.care === "inlay") &&
              action.payload === "occlusal"
                ? !state.cares.obturation.occlusal
                : false,
          },
        },
      };
    case C_OBTURATION_CB + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            cuspideBuccal:
              (state.cares.care === "obturation" ||
                state.cares.care === "partialcrown") &&
              action.payload === "cuspidebuccal"
                ? !state.cares.obturation.cuspideBuccal
                : false,
          },
        },
      };
    case C_OBTURATION_BP + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            bossePalatal:
              (state.cares.care === "obturation" ||
                state.cares.care === "partialcrown") &&
              action.payload === "bossepalatal"
                ? !state.cares.obturation.bossePalatal
                : false,
          },
        },
      };
    //MATERIAL
    case C_MATERIALS_COMPOSITE + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            composite: !state.cares.materials.composite,
            ceramic: false,
            gold: false,
            baseMetal: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_CERAMIC + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            ceramic: !state.cares.materials.ceramic,
            composite: false,
            gold: false,
            baseMetal: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_GOLD + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            gold: !state.cares.materials.gold,
            composite: false,
            ceramic: false,
            baseMetal: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_BASEMETAL + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            baseMetal: !state.cares.materials.baseMetal,
            composite: false,
            ceramic: false,
            gold: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_PROVISIONAL + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            provisional: !state.cares.materials.provisional,
            composite: false,
            ceramic: false,
            gold: false,
            baseMetal: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_AMALGAM + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            amalgam: !state.cares.materials.amalgam,
            composite: false,
            ceramic: false,
            gold: false,
            baseMetal: false,
            provisional: false,
          },
        },
      };
    case C_PARTIALCROWN_B + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          partialCrown: {
            ...state.cares.partialCrown,
            buccal: !state.cares.partialCrown.buccal,
          },
        },
      };
    case C_PARTIALCROWN_P + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          partialCrown: {
            ...state.cares.partialCrown,
            palatal: !state.cares.partialCrown.palatal,
          },
        },
      };
    case C_PARTIALCROWN_CB + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          partialCrown: {
            ...state.cares.partialCrown,
            cuspideBuccal: !state.cares.partialCrown.cuspideBuccal,
          },
        },
      };
    case C_PARTIALCROWN_BP + 24:
      return {
        ...state,
        cares: {
          ...state.cares,
          partialCrown: {
            ...state.cares.partialCrown,
            bossePalatal: !state.cares.partialCrown.bossePalatal,
          },
        },
      };
    case C_QUALITY_SUFFICIENT + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          quality: {
            ...state?.cares?.quality,
            sufficient: !state?.cares?.quality?.sufficient,
            uncertain: false,
            insufficient: false,
          },
        },
      };
    case C_QUALITY_UNCERTAIN + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          quality: {
            ...state?.cares?.quality,
            sufficient: false,
            uncertain: !state?.cares?.quality?.uncertain,
            insufficient: false,
          },
        },
      };
    case C_QUALITY_INSUFFICIENT + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          quality: {
            ...state?.cares?.quality,
            sufficient: false,
            uncertain: false,
            insufficient: !state?.cares?.quality?.insufficient,
          },
        },
      };
    case C_DETAIL_SURPLUS + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          detail: {
            surplus: !state?.cares?.detail?.surplus,
            flush: false,
            deficit: false,
          },
        },
      };
    case C_DETAIL_FLUSH + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          detail: {
            ...state?.cares?.detail,
            surplus: false,
            flush: !state?.cares?.detail?.flush,
            deficit: false,
          },
        },
      };
    case C_DETAIL_DEFICIT + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          detail: {
            ...state?.cares?.detail,
            surplus: false,
            flush: false,
            deficit: !state?.cares?.detail?.deficit,
          },
        },
      };
    case C_CROWNTYPE_SIMPLECROWN + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          crownType: {
            ...state?.cares?.crownType,
            simplecrown: !state?.cares?.crownType?.simplecrown,
            pillar: false,
          },
        },
        tooth: {
          ...state?.tooth,
          pontic: false,
        },
      };
    case C_CROWNTYPE_PILLAR + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          crownType: {
            ...state?.cares?.crownType,
            simplecrown: false,
            pillar: !state?.cares?.crownType?.pillar,
          },
        },
        tooth: {
          ...state?.tooth,
          pontic: false,
        },
      };
    case C_CROWNTYPE_PONTIC + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          crownType: {
            ...state?.cares?.crownType,
            simplecrown: false,
            pillar: false,
          },
        },
        tooth: {
          ...state?.tooth,
          pontic: !state?.tooth?.pontic,
        },
      };
    case C_TOOTHTYPE + 24:
      return {
        ...state,
        tooth: {
          ...state?.tooth,
          dental: action.payload === "dental" ? !state?.tooth?.dental : false,
          implant:
            action.payload === "implant" ? !state?.tooth?.implant : false,
        },
      };
    case C_IMPLANTTYPE_BL + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          implantType: {
            ...state?.cares?.implantType,
            bonelevel: !state?.cares?.implantType?.bonelevel,
            tissuelevel: false,
          },
        },
      };
    case C_IMPLANTTYPE_TL + 24:
      return {
        ...state,
        cares: {
          ...state?.cares,
          implantType: {
            ...state?.cares?.implantType,
            bonelevel: false,
            tissuelevel: !state?.cares?.implantType?.tissuelevel,
          },
        },
      };
    default:
      return state;
  }
};

export default teeth24;
