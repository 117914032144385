import React, { useEffect } from 'react';
import withTheme from './hoc/withTheme';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';
import { useWindowDimensions } from './assets/helpers/helperUseWindowDimensions';
import AccountValidation from './components/Offline/AccountValidation';
import CheckSubcription from './components/Online/CheckSubcription';
import { ModalsRender } from './shared/Modals';
import { useTranslation } from 'react-i18next';
const Login = React.lazy(() => import('./components/Offline/Login'));
const Reset = React.lazy(() => import('./components/Offline/Reset'));
const Forget = React.lazy(() => import('./components/Offline/Forget'));
const Online = React.lazy(() => import('./components/Online'));
const AnamnesFormPatient = React.lazy(() =>
  import('./components/Offline/AnamnesFormPatient')
);
const ErrorPage = React.lazy(() => import('./shared/Error'));
const Report = React.lazy(() => import('./components/Offline/Report'));
const ReportPdf = React.lazy(() =>
  import('./components/Offline/Report/ReportPdf')
);
const queryClient = new QueryClient();

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    if (
      toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem)) &&
      !window.location.pathname.includes('/modules/anamnes/')
    ) {
      window.location.href = 'https://mobile.mediweb.lu';
    }
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  if (
    useWindowDimensions().width < 1250 &&
    !window.location.pathname.includes('/modules/anamnes/') &&
    !window.location.pathname.includes('/validation-user-account')
  ) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#2274a1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '2rem',
        }}
      >
        <span style={{ fontSize: 30, color: 'white' }}>
          Oups! Nous vous prions de bien passer sur un écran large pour une
          bonne expérience utilisateur
        </span>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ModalsRender />
        <Toaster position='top-right' reverseOrder={false} />
        <Switch>
          <Route exact path='/' component={Login} />
          <Route
            path='/validation-user-account/:id/:token'
            component={AccountValidation}
          />
          <Route path='/forget' component={Forget} />
          <Route path='/reset-password-secure' component={Reset} />
          <Route
            exact
            path='/modules/anamnes/:token/:formId'
            component={AnamnesFormPatient}
          />
          <Route exact path='/modules/report/:token' component={Report} />
          <Route exact path='/modules/reportPdf/:token' component={ReportPdf} />
          <Route path='/dashboard' component={Online} />
          <Route path='/check-subcription' component={CheckSubcription} />
          <Route path='*' component={ErrorPage} />
        </Switch>
      </BrowserRouter>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
};

export default withTheme(App);
