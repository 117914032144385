import { TOGGLE_THEME } from "./actions";

const initialState = {
  dark: false,
};

const theme = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return { ...state, dark: !state.dark };
    default:
      return state;
  }
};

export default theme;
