import { SEND_PDF } from "./action";

const initState = {
  patientName: "",
  sendPdf: false,
  patientId: "",
};

const sendPdf = (state = initState, action) => {
  switch (action.type) {
    case SEND_PDF:
      return {
        ...state,
        patientName: action?.payload?.patientName,
        patientId: action?.payload?.patientId,
        sendPdf: action?.payload?.sendPdf,
      };
    default:
      return state;
  }
};

export default sendPdf;
