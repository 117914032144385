export const DENTAL = "DENTAL";
export const setDental = () => {
  return {
    type: DENTAL,
  };
};
export const PAROD = "PAROD";
export const setParod = () => {
  return {
    type: PAROD,
  };
};
