//#####################################PATHOLOGIE##################################

//REQUEST
export const INIT = "INIT";
export const setInit = (numTooth, payload) => {
  return {
    type: INIT + numTooth,
    payload,
  };
};
export const LOADDATA = "LOADDATA";
export const setLoadData = (numTooth) => {
  return {
    type: LOADDATA + numTooth,
  };
};

export const LOADDATASUCCESS = "LOADDATASUCCESS";
export const loadDataSuccess = (data) => {
  return {
    type: LOADDATASUCCESS,
    payload: data,
  };
};
export const LOADDATAERROR = "LOADDATAERROR";
export const loadDataError = (error) => {
  return {
    type: LOADDATAERROR,
    payload: error,
  };
};

//******CARIE******
export const TOOTH = "TOOTH";
export const setTooth = (numTooth, toothType) => {
  return {
    type: TOOTH + numTooth,
    payload: toothType,
  };
};
export const RESET = "RESET";
export const setReset = (numTooth, payload) => {
  return {
    type: RESET + numTooth,
    payload: payload,
  };
};
//******CARIE******
export const P_CARIE_MESIAL = "P_CARIE_MESIAL";
export const setPCarieMesial = (numTooth) => {
  return {
    type: P_CARIE_MESIAL + numTooth,
  };
};

export const P_CARIE_DISTAL = "P_CARIE_DISTAL";
export const setPCarieDistal = (numTooth) => {
  return {
    type: P_CARIE_DISTAL + numTooth,
  };
};

export const P_CARIE_PALATAL = "P_CARIE_PALATAL";
export const setPCariePalatal = (numTooth) => {
  return {
    type: P_CARIE_PALATAL + numTooth,
  };
};

export const P_CARIE_BUCCAL = "P_CARIE_BUCCAL";
export const setPCarieBuccal = (numTooth) => {
  return {
    type: P_CARIE_BUCCAL + numTooth,
  };
};
export const P_CARIE_LINGUAL = "P_CARIE_LINGUAL";
export const setPCarieLingual = (numTooth) => {
  return {
    type: P_CARIE_LINGUAL + numTooth,
  };
};

export const P_CARIE_PALATAL_CERVICAL = "P_CARIE_PALATAL_CERVICAL";
export const setPCariePalatalCervical = (numTooth) => {
  return {
    type: P_CARIE_PALATAL_CERVICAL + numTooth,
  };
};

export const P_CARIE_BUCCAL_CERVICAL = "P_CARIE_BUCCAL_CERVICAL";
export const setPCarieBuccalCervical = (numTooth) => {
  return {
    type: P_CARIE_BUCCAL_CERVICAL + numTooth,
  };
};

export const P_CARIE_CERVICAL_LINGUAL = "P_CARIE_CERVICAL_LINGUAL";
export const setPCarieCervicalLingual = (numTooth) => {
  return {
    type: P_CARIE_CERVICAL_LINGUAL + numTooth,
  };
};

export const P_CARIE_SURFACE_PALATAL = "P_CARIE_SURFACE_PALATAL";
export const setPCarieSurfacePalatal = (numTooth) => {
  return {
    type: P_CARIE_SURFACE_PALATAL + numTooth,
  };
};

export const P_CARIE_SURFACE_BUCCAL = "P_CARIE_SURFACE_BUCCAL";
export const setPCarieSurfaceBuccal = (numTooth) => {
  return {
    type: P_CARIE_SURFACE_BUCCAL + numTooth,
  };
};

export const P_CARIE_SURFACE_LINGUAL = "P_CARIE_SURFACE_LINGUAL";
export const setPCarieSurfaceLingual = (numTooth) => {
  return {
    type: P_CARIE_SURFACE_LINGUAL + numTooth,
  };
};

export const P_CARIE_INCISAL = "P_CARIE_INCISAL";
export const setPCarieIncisal = (numTooth, payload) => {
  return {
    type: P_CARIE_INCISAL + numTooth,
    payload,
  };
};

export const P_CARIE_MESIAL_CLASSE4 = "P_CARIE_MESIAL_CLASSE4";
export const setPCarieMesialClasse4 = (numTooth, payload) => {
  return {
    type: P_CARIE_MESIAL_CLASSE4 + numTooth,
    payload,
  };
};

export const P_CARIE_CLASSE4_DISTAL = "P_CARIE_CLASSE4_DISTAL";
export const setPCarieClasse4Distal = (numTooth, payload) => {
  return {
    type: P_CARIE_CLASSE4_DISTAL + numTooth,
    payload,
  };
};

export const P_CARIE_BOSSE_P = "P_CARIE_BOSSE_P";
export const setPCarieBossePalatal = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_P + numTooth,
    payload,
  };
};
export const P_CARIE_BOSSE_L = "P_CARIE_BOSSE_L";
export const setPCarieBosseLingual = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_L + numTooth,
    payload,
  };
};

export const P_CARIE_CUSPIDE_B = "P_CARIE_CUSPIDE_B";
export const setPCarieCuspideBuccal = (numTooth, payload) => {
  return {
    type: P_CARIE_CUSPIDE_B + numTooth,
    payload,
  };
};

export const P_CARIE_OCCLUSAL = "P_CARIE_OCCLUSAL";
export const setPCarieOcclusal = (numTooth, payload) => {
  return {
    type: P_CARIE_OCCLUSAL + numTooth,
    payload,
  };
};

export const P_CARIE_BOSSE_MB = "P_CARIE_BOSSE_MB";
export const setPCarieBosseMesioBuccal = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_MB + numTooth,
    payload,
  };
};

export const P_CARIE_BOSSE_DB = "P_CARIE_BOSSE_DB";
export const setPCarieBosseDistoBuccal = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_DB + numTooth,
    payload,
  };
};
export const P_CARIE_BOSSE_MP = "P_CARIE_BOSSE_MP";
export const setPCarieBosseMesioPalatal = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_MP + numTooth,
    payload,
  };
};

export const P_CARIE_BOSSE_DP = "P_CARIE_BOSSE_DP";
export const setPCarieBosseDistoPalatal = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_DP + numTooth,
    payload,
  };
};

export const P_CARIE_BOSSE_ML = "P_CARIE_BOSSE_ML";
export const setPCarieBosseMesioLingual = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_ML + numTooth,
    payload,
  };
};

export const P_CARIE_BOSSE_DL = "P_CARIE_BOSSE_DL";
export const setPCarieBosseDistoLingual = (numTooth, payload) => {
  return {
    type: P_CARIE_BOSSE_DL + numTooth,
    payload,
  };
};

export const P_FCH = "P_FCH";
export const setPFCH = (numTooth) => {
  return {
    type: P_FCH + numTooth,
  };
};

export const P_FCV = "P_FCV";
export const setPFCV = (numTooth) => {
  return {
    type: P_FCV + numTooth,
  };
};

export const P_FRH = "P_FRH";
export const setPFRH = (numTooth) => {
  return {
    type: P_FRH + numTooth,
  };
};

export const P_FRV = "P_FRV";
export const setPFRV = (numTooth) => {
  return {
    type: P_FRV + numTooth,
  };
};

export const P_APICAL = "P_APICAL";
export const setPApical = (numTooth, payload) => {
  return {
    type: P_APICAL + numTooth,
    payload,
  };
};

export const P_DG = "P_DG";
export const setPDiscolorationGrey = (numTooth, payload) => {
  return {
    type: P_DG + numTooth,
    payload,
  };
};

export const P_DR = "P_DR";
export const setPDiscolorationRed = (numTooth, payload) => {
  return {
    type: P_DR + numTooth,
    payload,
  };
};

export const P_DY = "P_DY";
export const setPDiscolorationYellow = (numTooth, payload) => {
  return {
    type: P_DY + numTooth,
    payload,
  };
};

export const P_EROSION_PALATAL = "P_EROSION_PALATAL";
export const setPEErosionPanatal = (numTooth, payload) => {
  return {
    type: P_EROSION_PALATAL + numTooth,
    payload,
  };
};

export const P_EROSION_BUCCAL = "P_EROSION_BUCCAL";
export const setPEErosionBuccal = (numTooth, payload) => {
  return {
    type: P_EROSION_BUCCAL + numTooth,
    payload,
  };
};

export const P_ABRASION_PALATAL = "P_ABRASION_PALATAL";
export const setPAAbrasionPalatal = (numTooth, payload) => {
  return {
    type: P_ABRASION_PALATAL + numTooth,
    payload,
  };
};

export const P_ABRASION_BUCCAL = "P_ABRASION_BUCCAL";
export const setPAAbrasionBuccal = (numTooth, payload) => {
  return {
    type: P_ABRASION_BUCCAL + numTooth,
    payload,
  };
};

export const P_EROSION_SP = "P_EROSION_SP";
export const setPESurfacePalatal = (numTooth, payload) => {
  return {
    type: P_EROSION_SP + numTooth,
    payload,
  };
};

export const P_EROSION_SB = "P_EROSION_SB";
export const setPESurfaceBuccal = (numTooth, payload) => {
  return {
    type: P_EROSION_SB + numTooth,
    payload,
  };
};

export const P_EROSION_SL = "P_EROSION_SL";
export const setPESurfaceLingual = (numTooth, payload) => {
  return {
    type: P_EROSION_SL + numTooth,
    payload,
  };
};

//#####################################SOINS##################################

export const C_CARE = "C_CARE";
export const setCCare = (numTooth, payload) => {
  return {
    type: C_CARE + numTooth,
    payload,
  };
};
export const C_COURONNE = "C_COURONNE";
export const setCCouronne = (numTooth) => {
  return {
    type: C_COURONNE + numTooth,
  };
};

export const C_FACETTE_F = "C_FACETTE_F";
export const setCFFacette = (numTooth) => {
  return {
    type: C_FACETTE_F + numTooth,
  };
};

export const C_FACETTE_FB = "C_FACETTE_FB";
export const setCFFacetteBuccal = (numTooth) => {
  return {
    type: C_FACETTE_FB + numTooth,
  };
};

export const C_FACETTE_FP = "C_FACETTE_FP";
export const setCFFacettePalatal = (numTooth) => {
  return {
    type: C_FACETTE_FP + numTooth,
  };
};

export const C_FACETTE_L = "C_FACETTE_L";
export const setCFFacetteLingual = (numTooth) => {
  return {
    type: C_FACETTE_L + numTooth,
  };
};

export const C_OBTURATION_M = "C_OBTURATION_M";
export const setCOMesial = (numTooth) => {
  return {
    type: C_OBTURATION_M + numTooth,
  };
};

export const C_OBTURATION_D = "C_OBTURATION_D";
export const setCODistal = (numTooth) => {
  return {
    type: C_OBTURATION_D + numTooth,
  };
};

export const C_OBTURATION_O = "C_OBTURATION_O";
export const setCOOcclusal = (numTooth, payload) => {
  return {
    type: C_OBTURATION_O + numTooth,
    payload,
  };
};

export const C_OBTURATION_INCISAL = "C_OBTURATION_INCISAL";
export const setCOIncisal = (numTooth, payload) => {
  return {
    type: C_OBTURATION_INCISAL + numTooth,
    payload,
  };
};

export const C_OBTURATION_P = "C_OBTURATION_P";
export const setCOPalatal = (numTooth) => {
  return {
    type: C_OBTURATION_P + numTooth,
  };
};

export const C_OBTURATION_L = "C_OBTURATION_L";
export const setCOLingual = (numTooth) => {
  return {
    type: C_OBTURATION_L + numTooth,
  };
};

export const C_OBTURATION_B = "C_OBTURATION_B";
export const setCOBuccal = (numTooth) => {
  return {
    type: C_OBTURATION_B + numTooth,
  };
};

export const C_OBTURATION_PC = "C_OBTURATION_PC";
export const setCOPalatalCervical = (numTooth) => {
  return {
    type: C_OBTURATION_PC + numTooth,
  };
};
export const C_OBTURATION_LC = "C_OBTURATION_LC";
export const setCOLingualCervical = (numTooth) => {
  return {
    type: C_OBTURATION_LC + numTooth,
  };
};

export const C_OBTURATION_BC = "C_OBTURATION_BC";
export const setCOBuccalCervical = (numTooth) => {
  return {
    type: C_OBTURATION_BC + numTooth,
  };
};
export const C_OBTURATION_BML = "C_OBTURATION_BML";
export const setCOBosseMesioLingual = (numTooth, payload) => {
  return {
    type: C_OBTURATION_BML + numTooth,
    payload,
  };
};
export const C_OBTURATION_BDL = "C_OBTURATION_BDL";
export const setCOBosseDistoLingual = (numTooth, payload) => {
  return {
    type: C_OBTURATION_BDL + numTooth,
    payload,
  };
};

export const C_OBTURATION_SP = "C_OBTURATION_SP";
export const setCOSurfacePalatal = (numTooth) => {
  return {
    type: C_OBTURATION_SP + numTooth,
  };
};

export const C_OBTURATION_SL = "C_OBTURATION_SL";
export const setCOSurfaceLingual = (numTooth) => {
  return {
    type: C_OBTURATION_SL + numTooth,
  };
};

export const C_OBTURATION_SB = "C_OBTURATION_SB";
export const setCOSurfaceBuccal = (numTooth) => {
  return {
    type: C_OBTURATION_SB + numTooth,
  };
};

export const C_OBTURATION_MC4 = "C_OBTURATION_MC4";
export const setCOMesialClasse4 = (numTooth, payload) => {
  return {
    type: C_OBTURATION_MC4 + numTooth,
    payload,
  };
};

export const C_OBTURATION_C4D = "C_OBTURATION_C4D";
export const setCOClasse4Distal = (numTooth, payload) => {
  return {
    type: C_OBTURATION_C4D + numTooth,
    payload,
  };
};

export const C_OBTURATION_FB = "C_OBTURATION_FB";
export const setCOFacetteBuccal = (numTooth) => {
  return {
    type: C_OBTURATION_FB + numTooth,
  };
};

export const C_OBTURATION_FP = "C_OBTURATION_FP";
export const setCOFacettePalatal = (numTooth) => {
  return {
    type: C_OBTURATION_FP + numTooth,
  };
};

export const C_OBTURATION_CB = "C_OBTURATION_CB";
export const setCOCuspideBuccal = (numTooth, payload) => {
  return {
    type: C_OBTURATION_CB + numTooth,
    payload,
  };
};

export const C_OBTURATION_BP = "C_OBTURATION_BP";
export const setCOBossePalatal = (numTooth, payload) => {
  return {
    type: C_OBTURATION_BP + numTooth,
    payload,
  };
};
export const C_OBTURATION_BL = "C_OBTURATION_BL";
export const setCOBosseLingual = (numTooth, payload) => {
  return {
    type: C_OBTURATION_BL + numTooth,
    payload,
  };
};

export const P_O_BOSSE_MB = "P_O_BOSSE_MB";
export const setPOBosseMesioBuccal = (numTooth, payload) => {
  return {
    type: P_O_BOSSE_MB + numTooth,
    payload,
  };
};

export const P_O_BOSSE_DB = "P_O_BOSSE_DB";
export const setPOBosseDistoBuccal = (numTooth, payload) => {
  return {
    type: P_O_BOSSE_DB + numTooth,
    payload,
  };
};
export const P_O_BOSSE_MP = "P_O_BOSSE_MP";
export const setPOBosseMesioPalatal = (numTooth, payload) => {
  return {
    type: P_O_BOSSE_MP + numTooth,
    payload,
  };
};

export const P_O_BOSSE_DP = "P_O_BOSSE_DP";
export const setPOBosseDistoPalatal = (numTooth, payload) => {
  return {
    type: P_O_BOSSE_DP + numTooth,
    payload,
  };
};

/////////MATERIALS///////
export const C_MATERIALS_COMPOSITE = "C_MATERIALS_COMPOSITE";
export const setCMComposite = (numTooth) => {
  return {
    type: C_MATERIALS_COMPOSITE + numTooth,
  };
};

export const C_MATERIALS_CERAMIC = "C_MATERIALS_CERAMIC";
export const setCMCeramique = (numTooth) => {
  return {
    type: C_MATERIALS_CERAMIC + numTooth,
  };
};

export const C_MATERIALS_GOLD = "C_MATERIALS_GOLD";
export const setCMGold = (numTooth) => {
  return {
    type: C_MATERIALS_GOLD + numTooth,
  };
};

export const C_MATERIALS_BASEMETAL = "C_MATERIALS_BASEMETAL";
export const setCMBaseMetal = (numTooth) => {
  return {
    type: C_MATERIALS_BASEMETAL + numTooth,
  };
};

export const C_MATERIALS_PROVISIONAL = "C_MATERIALS_PROVISIONAL";
export const setCMProvisional = (numTooth) => {
  return {
    type: C_MATERIALS_PROVISIONAL + numTooth,
  };
};

export const C_MATERIALS_AMALGAM = "C_MATERIALS_AMALGAM";
export const setCMAmalgam = (numTooth) => {
  return {
    type: C_MATERIALS_AMALGAM + numTooth,
  };
};

/////////QUALITY///////

export const C_QUALITY_SUFFICIENT = "C_QUALITY_SUFFICIENT";
export const setCQSufficient = (numTooth) => {
  return {
    type: C_QUALITY_SUFFICIENT + numTooth,
  };
};

export const C_QUALITY_UNCERTAIN = "C_QUALITY_UNCERTAIN";
export const setCQUncertain = (numTooth) => {
  return {
    type: C_QUALITY_UNCERTAIN + numTooth,
  };
};

export const C_QUALITY_INSUFFICIENT = "C_QUALITY_INSUFFICIENT";
export const setCQInsufficient = (numTooth) => {
  return {
    type: C_QUALITY_INSUFFICIENT + numTooth,
  };
};

/////////DETAIL///////

export const C_DETAIL_SURPLUS = "C_DETAIL_SURPLUS";
export const setCDSurplus = (numTooth) => {
  return {
    type: C_DETAIL_SURPLUS + numTooth,
  };
};

export const C_DETAIL_FLUSH = "C_DETAIL_FLUSH";
export const setCDFlush = (numTooth) => {
  return {
    type: C_DETAIL_FLUSH + numTooth,
  };
};

export const C_DETAIL_DEFICIT = "C_DETAIL_DEFICIT";
export const setCDDeficit = (numTooth) => {
  return {
    type: C_DETAIL_DEFICIT + numTooth,
  };
};

/////////CROWN TYPE///////

export const C_CROWNTYPE_SIMPLECROWN = "C_CROWNTYPE_SIMPLECROWN";
export const setCCTSimpleCrown = (numTooth) => {
  return {
    type: C_CROWNTYPE_SIMPLECROWN + numTooth,
  };
};

export const C_CROWNTYPE_PILLAR = "C_CROWNTYPE_PILLAR";
export const setCCTPillar = (numTooth) => {
  return {
    type: C_CROWNTYPE_PILLAR + numTooth,
  };
};

export const C_CROWNTYPE_PONTIC = "C_CROWNTYPE_PONTIC";
export const setCCTPontic = (numTooth) => {
  return {
    type: C_CROWNTYPE_PONTIC + numTooth,
  };
};

export const C_TOOTHTYPE = "C_TOOTHTYPE";
export const setCToothType = (numTooth, payload) => {
  return {
    type: C_TOOTHTYPE + numTooth,
    payload,
  };
};
/////////IMPLANT TYPE///////

export const C_IMPLANTTYPE_BL = "C_IMPLANTTYPE_BL";
export const setCITBonelevel = (numTooth) => {
  return {
    type: C_IMPLANTTYPE_BL + numTooth,
  };
};

export const C_IMPLANTTYPE_TL = "C_IMPLANTTYPE_TL";
export const setCITissulevel = (numTooth) => {
  return {
    type: C_IMPLANTTYPE_TL + numTooth,
  };
};
/////////PARTIAL CROWN///////

export const C_PARTIALCROWN_B = "C_PARTIALCROWN_B";
export const setCPCBuccal = (numTooth) => {
  return {
    type: C_PARTIALCROWN_B + numTooth,
  };
};

export const C_PARTIALCROWN_P = "C_PARTIALCROWN_P";
export const setCPCPalatal = (numTooth) => {
  return {
    type: C_PARTIALCROWN_P + numTooth,
  };
};
export const C_PARTIALCROWN_CB = "C_PARTIALCROWN_CB";
export const setCPCCuspideBuccal = (numTooth) => {
  return {
    type: C_PARTIALCROWN_CB + numTooth,
  };
};
export const C_PARTIALCROWN_BP = "C_PARTIALCROWN_BP";
export const setCPCBossePalatal = (numTooth) => {
  return {
    type: C_PARTIALCROWN_BP + numTooth,
  };
};

export const P_PROBLEM_DEVELOP = "P_PROBLEM_DEVELOP";
export const setPProblemDevelop = (numTooth, payload) => {
  return {
    type: P_PROBLEM_DEVELOP + numTooth,
    payload,
  };
};
export const P_DENTINE = "P_DENTINE";
export const setPDentine = (numTooth, payload) => {
  return {
    type: P_DENTINE + numTooth,
    payload,
  };
};
export const P_EMAIL = "P_EMAIL";
export const setPEmail = (numTooth, payload) => {
  return {
    type: P_EMAIL + numTooth,
    payload,
  };
};
export const P_PRESENTOFCAVITY = "P_PRESENTOFCAVITY";
export const setPPresentOfCavity = (numTooth, payload) => {
  return {
    type: P_PRESENTOFCAVITY + numTooth,
    payload,
  };
};
export const P_ABSENCEOFCAVITY = "P_ABSENCEOFCAVITY";
export const setPAbsenceOfCavity = (numTooth, payload) => {
  return {
    type: P_ABSENCEOFCAVITY + numTooth,
    payload,
  };
};
export const P_PULP_DAMAGE = "P_PULP_DAMAGE";
export const setPulpDamage = (numTooth, payload) => {
  return {
    type: P_PULP_DAMAGE + numTooth,
    payload,
  };
};
export const P_PULP_NO_AFFECTED = "P_PULP_NO_AFFECTED";
export const setPulpNotAffected = (numTooth, payload) => {
  return {
    type: P_PULP_NO_AFFECTED + numTooth,
    payload,
  };
};
export const P_C1 = "P_C1";
export const setC1 = (numTooth, payload) => {
  return {
    type: P_C1 + numTooth,
    payload,
  };
};
export const P_C2 = "P_C2";
export const setC2 = (numTooth, payload) => {
  return {
    type: P_C2 + numTooth,
    payload,
  };
};
export const P_C3 = "P_C3";
export const setC3 = (numTooth, payload) => {
  return {
    type: P_C3 + numTooth,
    payload,
  };
};
export const P_C4 = "P_C4";
export const setC4 = (numTooth, payload) => {
  return {
    type: P_C4 + numTooth,
    payload,
  };
};
