import SuccessIcon from "../../../assets/images/success.gif";
import AlertIcon from "../../../assets/images/alert.gif";
import { helperUriParser } from "../../../assets/helpers/helperParseUri";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URI } from "../../../assets/app.config";
import Loader from "../../../shared/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { displayToast } from "../../../assets/helpers/helperToast";
import { useTranslation } from 'react-i18next';

const AccountValidation = () => {
  //
  const { t } = useTranslation();
  const history = useHistory();
  const userId = helperUriParser(2);
  const userToken = helperUriParser(3);

  const [verifResponse, setVerifResponse] = useState({
    success: null,
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const verifyAccount = useCallback(async () => {
    setLoading(true);
    axios
      .get(`${BASE_URI}user/verify/${userId}/${userToken}`)
      .then((res) => {
        setVerifResponse({
          success: res.data.success,
          message: res.data.message,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [userId, userToken]);

  useEffect(() => {
    verifyAccount();
  }, [verifyAccount]);

  const handleRedirectLogin = () => history.push("/");

  const handleReconfirmAccount = () => {
    setLoading(true);
    axios
      .post(`${BASE_URI}user/reconfirm/${userId}`)
      .then((res) => {
        setLoading(false);
        displayToast(true, res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        displayToast(false, t('AN_ERROR_HAS_OCCURRED'));
      });
  };

  if (loading) return <Loader />;
  return (
    <div className="accountValidation">
      {verifResponse.success !== null && (
        <div className="accountValidation--success">
          <img
            src={verifResponse.success ? SuccessIcon : AlertIcon}
            alt={verifResponse.success ? "success icon" : "alert icon"}
          />
          <p>
            <span>
              {verifResponse.success ? t('CONGRATULATION') : t('OUPS_SORRY')}
            </span>
            <br /> {verifResponse.message}
          </p>
          <button
            onClick={() =>
              verifResponse.success
                ? handleRedirectLogin()
                : handleReconfirmAccount()
            }
          >
            {verifResponse.success
              ? t('LOGIN') 
              : t("RESEND_CONFIRMATION")}
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountValidation;
