import {
  GINGIVAL_MARGIN,
  SOUNDING_DEPTH,
  TOOTH_PART,
  BLEEDING,
  PLAQUE,
  PUS,
  TARTAR,
  FURCATION,
  RESET,
  INIT_PAROD,
} from "../action";

const initState = {
  load: false,
  distoLingual: {
    distoLingual: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  lingual: {
    lingual: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  mesioLingual: {
    mesioLingual: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  distoBuccal: {
    distoBuccal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  buccal: {
    buccal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  mesioBuccal: {
    mesioBuccal: false,
    gingivalMargin: 0,
    soundingDepth: 0,
    bleeding: false,
    plaque: false,
    pus: false,
    tartar: false,
  },
  positiveData: false,
  furcation: {
    levelOne: false,
    levelTwo: false,
    levelThree: false,
  },
};

const parodontal47 = (state = initState, action) => {
  switch (action.type) {
    //REQUEST
    case INIT_PAROD + 47:
      return {
        ...state,
        ...action?.payload,
        load: true,
      };
    case RESET + 47:
      return { ...initState, load: true };
    case TOOTH_PART + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          distoLingual: action.payload === "distolingual" && true,
        },
        lingual: {
          ...state.lingual,
          lingual: action.payload === "lingual" && true,
        },
        mesioLingual: {
          ...state.mesioLingual,
          mesioLingual: action.payload === "mesiolingual" && true,
        },
        distoBuccal: {
          ...state.distoBuccal,
          distoBuccal: action.payload === "distobuccal" && true,
        },
        buccal: {
          ...state.buccal,
          buccal: action.payload === "buccal" && true,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          mesioBuccal: action.payload === "mesiobuccal" && true,
        },
      };
    case SOUNDING_DEPTH + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          soundingDepth:
            state.distoLingual.distoLingual && action.payload !== "+/-"
              ? action.payload
              : state.distoLingual.soundingDepth,
        },
        lingual: {
          ...state.lingual,
          soundingDepth:
            state.lingual.lingual && action.payload !== "+/-"
              ? action.payload
              : state.lingual.soundingDepth,
        },
        mesioLingual: {
          ...state.mesioLingual,
          soundingDepth:
            state.mesioLingual.mesioLingual && action.payload !== "+/-"
              ? action.payload
              : state.mesioLingual.soundingDepth,
        },
        distoBuccal: {
          ...state.distoBuccal,
          soundingDepth:
            state.distoBuccal.distoBuccal && action.payload !== "+/-"
              ? action.payload
              : state.distoBuccal.soundingDepth,
        },
        buccal: {
          ...state.buccal,
          soundingDepth:
            state.buccal.buccal && action.payload !== "+/-"
              ? action.payload
              : state.buccal.soundingDepth,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          soundingDepth:
            state.mesioBuccal.mesioBuccal && action.payload !== "+/-"
              ? action.payload
              : state.mesioBuccal.soundingDepth,
        },
      };
    case GINGIVAL_MARGIN + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          gingivalMargin:
            state.distoLingual.distoLingual && action.payload !== "+/-"
              ? action.payload
              : state.distoLingual.gingivalMargin,
        },
        lingual: {
          ...state.lingual,
          gingivalMargin:
            state.lingual.lingual && action.payload !== "+/-"
              ? action.payload
              : state.lingual.gingivalMargin,
        },
        mesioLingual: {
          ...state.mesioLingual,
          gingivalMargin:
            state.mesioLingual.mesioLingual && action.payload !== "+/-"
              ? action.payload
              : state.mesioLingual.gingivalMargin,
        },
        distoBuccal: {
          ...state.distoBuccal,
          gingivalMargin:
            state.distoBuccal.distoBuccal && action.payload !== "+/-"
              ? action.payload
              : state.distoBuccal.gingivalMargin,
        },
        buccal: {
          ...state.buccal,
          gingivalMargin:
            state.buccal.buccal && action.payload !== "+/-"
              ? action.payload
              : state.buccal.gingivalMargin,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          gingivalMargin:
            state.mesioBuccal.mesioBuccal && action.payload !== "+/-"
              ? action.payload
              : state.mesioBuccal.gingivalMargin,
        },
        positiveData:
          action.payload === "+/-" ? !state.positiveData : state.positiveData,
      };
    case BLEEDING + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          bleeding: state.distoLingual.distoLingual
            ? !state.distoLingual.bleeding
            : state.distoLingual.bleeding,
        },
        lingual: {
          ...state.lingual,
          bleeding: state.lingual.lingual
            ? !state.lingual.bleeding
            : state.lingual.bleeding,
        },
        mesioLingual: {
          ...state.mesioLingual,
          bleeding: state.mesioLingual.mesioLingual
            ? !state.mesioLingual.bleeding
            : state.mesioLingual.bleeding,
        },
        distoBuccal: {
          ...state.distoBuccal,
          bleeding: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.bleeding
            : state.distoBuccal.bleeding,
        },
        buccal: {
          ...state.buccal,
          bleeding: state.buccal.buccal
            ? !state.buccal.bleeding
            : state.buccal.bleeding,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          bleeding: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.bleeding
            : state.mesioBuccal.bleeding,
        },
      };
    case PLAQUE + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          plaque: state.distoLingual.distoLingual
            ? !state.distoLingual.plaque
            : state.distoLingual.plaque,
        },
        lingual: {
          ...state.lingual,
          plaque: state.lingual.lingual
            ? !state.lingual.plaque
            : state.lingual.plaque,
        },
        mesioLingual: {
          ...state.mesioLingual,
          plaque: state.mesioLingual.mesioLingual
            ? !state.mesioLingual.plaque
            : state.mesioLingual.plaque,
        },
        distoBuccal: {
          ...state.distoBuccal,
          plaque: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.plaque
            : state.distoBuccal.plaque,
        },
        buccal: {
          ...state.buccal,
          plaque: state.buccal.buccal
            ? !state.buccal.plaque
            : state.buccal.plaque,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          plaque: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.plaque
            : state.mesioBuccal.plaque,
        },
      };
    case PUS + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          pus: state.distoLingual.distoLingual
            ? !state.distoLingual.pus
            : state.distoLingual.pus,
        },
        lingual: {
          ...state.lingual,
          pus: state.lingual.lingual ? !state.lingual.pus : state.lingual.pus,
        },
        mesioLingual: {
          ...state.mesioLingual,
          pus: state.mesioLingual.mesioLingual
            ? !state.mesioLingual.pus
            : state.mesioLingual.pus,
        },
        distoBuccal: {
          ...state.distoBuccal,
          pus: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.pus
            : state.distoBuccal.pus,
        },
        buccal: {
          ...state.buccal,
          pus: state.buccal.buccal ? !state.buccal.pus : state.buccal.pus,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          pus: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.pus
            : state.mesioBuccal.pus,
        },
      };
    case TARTAR + 47:
      return {
        ...state,
        distoLingual: {
          ...state.distoLingual,
          tartar: state.distoLingual.distoLingual
            ? !state.distoLingual.tartar
            : state.distoLingual.tartar,
        },
        lingual: {
          ...state.lingual,
          tartar: state.lingual.lingual
            ? !state.lingual.tartar
            : state.lingual.tartar,
        },
        mesioLingual: {
          ...state.mesioLingual,
          tartar: state.mesioLingual.mesioLingual
            ? !state.mesioLingual.tartar
            : state.mesioLingual.tartar,
        },
        distoBuccal: {
          ...state.distoBuccal,
          tartar: state.distoBuccal.distoBuccal
            ? !state.distoBuccal.tartar
            : state.distoBuccal.tartar,
        },
        buccal: {
          ...state.buccal,
          tartar: state.buccal.buccal
            ? !state.buccal.tartar
            : state.buccal.tartar,
        },
        mesioBuccal: {
          ...state.mesioBuccal,
          tartar: state.mesioBuccal.mesioBuccal
            ? !state.mesioBuccal.tartar
            : state.mesioBuccal.tartar,
        },
      };
    case FURCATION + 47:
      return {
        ...state,
        furcation: {
          ...state.furcation,
          levelOne:
            action.payload === "levelOne" ? !state.furcation.levelOne : false,
          levelTwo:
            action.payload === "levelTwo" ? !state.furcation.levelTwo : false,
          levelThree:
            action.payload === "levelThree"
              ? !state.furcation.levelThree
              : false,
        },
      };
    default:
      return state;
  }
};

export default parodontal47;
