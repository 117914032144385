//REQUEST
export const INIT_PAROD = "INIT_PAROD";
export const setInitParod = (numTooth, payload) => {
  return {
    type: INIT_PAROD + numTooth,
    payload,
  };
};

export const TOOTH_PART = "TOOTH_PART";
export const setToothPart = (toothNum, part) => {
  return {
    type: TOOTH_PART + toothNum,
    payload: part,
  };
};
export const RESET = "RESET";
export const setResetParod = (numTooth) => {
  return {
    type: RESET + numTooth,
  };
};
export const SOUNDING_DEPTH = "SOUNDING_DEPTH";
export const setSoundingDepth = (toothNum, value) => {
  return {
    type: SOUNDING_DEPTH + toothNum,
    payload: value,
  };
};

export const GINGIVAL_MARGIN = "GINGIVAL_MARGIN";
export const setGingivalMargin = (toothNum, value) => {
  return {
    type: GINGIVAL_MARGIN + toothNum,
    payload: value,
  };
};

export const BLEEDING = "BLEEDING";
export const setBleeding = (toothNum, sickSelected) => {
  return {
    type: BLEEDING + toothNum,
    payload: sickSelected,
  };
};

export const PLAQUE = "PLAQUE";
export const setPlaque = (toothNum, sickSelected) => {
  return {
    type: PLAQUE + toothNum,
    payload: sickSelected,
  };
};

export const PUS = "PUS";
export const setPus = (toothNum, sickSelected) => {
  return {
    type: PUS + toothNum,
    payload: sickSelected,
  };
};

export const TARTAR = "TARTAR";
export const setTartar = (toothNum, sickSelected) => {
  return {
    type: TARTAR + toothNum,
    payload: sickSelected,
  };
};

export const FURCATION = "FURCATION";
export const setFurcation = (toothNum, level) => {
  return {
    type: FURCATION + toothNum,
    payload: level,
  };
};
