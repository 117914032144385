import {
  SELECTED_SICKS,
  LOAD_SICKS,
  LOAD_SICKS_SUCCESS,
  LOAD_SICKS_ERROR,
} from "./actions";

const initState = {
  loading: false,
  sicksData: [],
  error: "",
  sicks: [],
};

const GeneralMedecine = (state = initState, action) => {
  switch (action.type) {
    case SELECTED_SICKS:
      return {
        ...state,
        sicks: state.sicks.includes(action.payload)
          ? state.sicks.filter((s) => s !== action.payload.toLowerCase())
          : [...state.sicks, action.payload.toLowerCase()],
      };
    case LOAD_SICKS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SICKS_SUCCESS:
      return {
        ...state,
        loading: false,
        sicksData: action.payload,
        sicks: action.payload,
        error: "",
      };
    case LOAD_SICKS_ERROR:
      return {
        ...state,
        loading: false,
        sicksData: state.sicksData,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default GeneralMedecine;
