import { DENTAL, PAROD } from "./action";

const initState = {
  dental: false,
  parod: false,
};

const teethOverview = (state = initState, action) => {
  switch (action.type) {
    case DENTAL:
      return {
        ...state,
        dental: !state.dental,
      };
    case PAROD:
      return {
        ...state,
        parod: !state.parod,
      };
    default:
      return state;
  }
};
export default teethOverview;
