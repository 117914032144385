export const helperAtobToken = (key, index) => {
  if (sessionStorage.getItem(key) !== null) {
    const res = JSON.parse(
      window.atob(sessionStorage.getItem(key).split(".")[1])
    );
    if (index) {
      return res[index];
    }
    return res;
  }
};

export const helperAtobToken2 = (token, index) => {
  try {
    if (!!!token) return null;

    const res = JSON.parse(window.atob(token.split(".")[1]));

    if (index) {
      return res[index];
    }

    return res;
  } catch (err) {
    return {};
  }
};
