import { useSelector } from "react-redux";
import loader from "../../assets/images/loader.gif";
import loaderWhite from "../../assets/images/loaderWhite.gif";
const Loader = () => {
  const dark = useSelector((s) => s.theme.dark);
  return (
    <div className='loader'>
      <img src={dark ? loaderWhite : loader} alt='loader' />
    </div>
  );
};

export default Loader;
