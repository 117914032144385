export const ENDOD = "ENDOD";
export const setEndod = (toothNum, endod) => {
  return {
    type: ENDOD + toothNum,
    payload: endod,
  };
};
export const RESET = "RESET";
export const setResetEndod = (numTooth) => {
  return {
    type: RESET + numTooth,
  };
};
export const ELECTRIC = "ELECTRIC";
export const setElectric = (toothNum, electricValue) => {
  return {
    type: ELECTRIC + toothNum,
    payload: electricValue,
  };
};
export const POSITIVE = "POSITIVE";
export const setPositive = (toothNum) => {
  return {
    type: POSITIVE + toothNum,
  };
};
export const LIMITED = "LIMITED";
export const setLimited = (toothNum) => {
  return {
    type: LIMITED + toothNum,
  };
};
export const UNPLEASANT = "UNPLEASANT";
export const setUnpleasant = (toothNum) => {
  return {
    type: UNPLEASANT + toothNum,
  };
};
export const PAINFULSTIMULUS = "PAINFULSTIMULUS";
export const setPainStimulus = (toothNum) => {
  return {
    type: PAINFULSTIMULUS + toothNum,
  };
};
export const PERSISTENTPAIN = "PERSISTENTPAIN";
export const setPersistenpain = (toothNum) => {
  return {
    type: PERSISTENTPAIN + toothNum,
  };
};
export const UNCERTAIN = "UNCERTAIN";
export const setUncertain = (toothNum) => {
  return {
    type: UNCERTAIN + toothNum,
  };
};
export const NEGATIVE = "NEGATIVE";
export const setNegative = (toothNum) => {
  return {
    type: NEGATIVE + toothNum,
  };
};
export const NOTAPPLICABLE = "NOTAPPLICABLE";
export const setNotApplicable = (toothNum) => {
  return {
    type: NOTAPPLICABLE + toothNum,
  };
};
export const EXISTINGROOTCANAL = "EXISTINGROOTCANAL";
export const setExistingRootCanal = (toothNum) => {
  return {
    type: EXISTINGROOTCANAL + toothNum,
  };
};
export const TREATMENTALREADY = "TREATMENTALREADY";
export const setTreatmentAlready = (toothNum) => {
  return {
    type: TREATMENTALREADY + toothNum,
  };
};
export const PAINLESS = "PAINLESS";
export const setPainless = (toothNum) => {
  return {
    type: PAINLESS + toothNum,
  };
};
export const PAINFUL = "PAINFUL";
export const setPainful = (toothNum) => {
  return {
    type: PAINFUL + toothNum,
  };
};
export const UNPLEASANTCOLDHEAT = "UNPLEASANTCOLDHEAT";
export const setUnpleasantColdHeat = (toothNum) => {
  return {
    type: UNPLEASANTCOLDHEAT + toothNum,
  };
};
