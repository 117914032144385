import axios from 'axios';
import { useState } from 'react';
import UploadImg from '../../assets/images/uploadImg.svg';
import { uploadFile } from '../../assets/utils/uploadFiles';
import { BASE_URI, COMPARATOR_BASE_URI } from '../../assets/app.config';
import { displayToast } from '../../assets/helpers/helperToast';
import { initImgManage } from '../../assets/contants';
import { Textarea } from '../Field';
import InputLabel from '../InputLabel';
import { Button } from '../Button';
import { setShowAddProjectModal } from '../../redux/modals/action';
import { useDispatch, useSelector } from 'react-redux';
import { helperUriParser } from '../../assets/helpers/helperParseUri';
import { useTranslation } from 'react-i18next';

const initImg = {
  image: null,
  name: '',
  params: initImgManage,
};
const initForm = {
  patientId: null,
  ownerId: null,
  name: '',
  comment: '',
  image1: initImg,
  image2: initImg,
  image3: initImg,
  image4: initImg,
  layout: 1,
};

export const AddProjectModal = () => {
  //

  const [loading, setLoading] = useState(false);
  const clientId = helperUriParser(3);
  const [form, setForm] = useState(initForm);
  const dispatch = useDispatch();
  const doctor = useSelector((s) => s.user.user);
  const { t } = useTranslation();

  const onChangeImage = (e) => {
    const { name, files } = e.target;
    setForm({
      ...form,
      [name]: {
        ...form[name],
        image: files[0],
        name: files[0]?.name,
      },
    });
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: {
        ...form[name],
        name: value,
      },
    });
  };

  const handleShowProjectModal = () => {
    dispatch(setShowAddProjectModal());
  };

  const onCreateProject = async () => {
    setLoading(true);

    try {
      const { name } = form || {};
      if (!name || !name.length) {
        setLoading(false);
        displayToast('warning', t('ENTER_A_TITLE'));
        return;
      }

      const images = {
        image1: form.image1,
        image2: form.image2,
        image3: form.image3,
        image4: form.image4,
      };

      const uploadPromises = Object.entries(images)
        .filter(([key, img]) => img?.image?.name)
        .map(async ([key, img]) => {
          images[key].image = await uploadFile(img.image);
        });

      await Promise.all(uploadPromises);

      const layoutNum = uploadPromises.length;

      if (!layoutNum) {
        setLoading(false);
        displayToast('warning', t('UPLOAD_AT_LEAST_ONE_IMAGE'));
        return;
      }

      const projectData = await axios.post(`${BASE_URI}comparator/add`, {
        ...form,
        ownerId: doctor?._id,
        patientId: clientId,
        ...images,
        layout: layoutNum,
      });

      setForm(initForm);
      setLoading(false);
      handleShowProjectModal();
      displayToast('success', t('PROJECT_CREATED'));

      window.open(
        `${COMPARATOR_BASE_URI}comparator/${projectData?.data?._id}`,
        '_blank'
      );
    } catch (err) {
      setLoading(false);
      displayToast('error', t('AN_ERROR_HAS_OCCURRED'));
    }
  };

  return (
    <>
      <div className='add-project-modal--bg' onClick={handleShowProjectModal} />
      <div className='add-project-modal'>
        <p className='add-project-modal--title'>{t('NEW_PROJECT')}</p>
        <div className='add-project-modal--body'>
          <div className='add-project-modal--form'>
            <InputLabel
              label={t('NAME')}
              onChange={(e) => setForm((s) => ({ ...s, name: e.target.value }))}
              value={form.name}
            />
            <Textarea
              label={t('COMMENT')}
              onChange={(e) =>
                setForm((s) => ({ ...s, comment: e.target.value }))
              }
              value={form.comment}
            />
          </div>
          <div className='add-project-modal--images'>
            <p>{t('SELECT_UP_TO_4_IMAGES')}</p>
            <Image
              position={1}
              name='image1'
              onChangeImg={onChangeImage}
              onChangeInput={onChangeInput}
              value={form.image1.name}
              img={form.image1.image}
            />
            <Image
              position={2}
              name='image2'
              onChangeImg={onChangeImage}
              onChangeInput={onChangeInput}
              value={form.image2.name}
              img={form.image2.image}
            />
            <Image
              position={3}
              name='image3'
              onChangeImg={onChangeImage}
              onChangeInput={onChangeInput}
              value={form.image3.name}
              img={form.image3.image}
            />
            <Image
              position={4}
              name='image4'
              onChangeImg={onChangeImage}
              onChangeInput={onChangeInput}
              value={form.image4.name}
              img={form.image4.image}
            />
          </div>
        </div>
        <div className='add-project-modal--btns'>
          <Button
            label={t('CANCEL')}
            width='48%'
            border
            color='#222222'
            onClick={handleShowProjectModal}
          />
          <Button
            disabled={loading}
            label={loading ? t('LOADING') : t('CREATE_PROJECT')}
            width='48%'
            onClick={onCreateProject}
          />
        </div>
      </div>
    </>
  );
};

const Image = ({ position, name, value, onChangeInput, onChangeImg, img }) => {
  let imageSrc = null;
  if (img) {
    imageSrc = URL.createObjectURL(img);
  }

  return (
    <div className='add-project-modal--image'>
      <input
        type='file'
        accept='image/*'
        id={position}
        hidden
        onChange={onChangeImg}
        name={name}
      />
      <label htmlFor={position} className='add-project-modal--image-img'>
        <img src={imageSrc || UploadImg} alt='uploadimg' />
      </label>
      <InputLabel
        label=''
        personnalisation='w-80'
        onChange={onChangeInput}
        name={name}
        value={value}
      />
    </div>
  );
};
