import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import './services/i18n';

import "./styles/base.scss";
import 'react-phone-input-2/lib/style.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/storeConfig";
import Loader from "./shared/Loader";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
