import {
  P_CARIE_MESIAL,
  P_CARIE_DISTAL,
  P_CARIE_PALATAL,
  P_CARIE_BUCCAL,
  P_CARIE_PALATAL_CERVICAL,
  P_CARIE_BUCCAL_CERVICAL,
  P_CARIE_SURFACE_PALATAL,
  P_CARIE_SURFACE_BUCCAL,
  // P_CARIE_BOSSE_P,
  P_CARIE_BOSSE_L,
  P_CARIE_CUSPIDE_B,
  P_CARIE_LINGUAL,
  P_CARIE_CERVICAL_LINGUAL,
  P_CARIE_SURFACE_LINGUAL,
  P_CARIE_MESIAL_CLASSE4,
  P_CARIE_CLASSE4_DISTAL,
  P_FCH,
  P_FRH,
  P_FRV,
  P_APICAL,
  P_ABRASION_PALATAL,
  P_DG,
  P_DR,
  P_DY,
  P_EROSION_PALATAL,
  P_EROSION_BUCCAL,
  // P_EROSION_SP,
  P_EROSION_SB,
  P_EROSION_SL,
  C_COURONNE,
  // C_FACETTE_F,
  // C_FACETTE_FB,
  // C_FACETTE_FP,
  C_OBTURATION_M,
  C_OBTURATION_D,
  // C_OBTURATION_P,
  C_OBTURATION_B,
  // C_OBTURATION_PC,
  C_OBTURATION_BC,
  // C_OBTURATION_SP,
  // C_OBTURATION_SB,
  // C_OBTURATION_MC4,
  // C_OBTURATION_C4D,
  // C_OBTURATION_FB,
  // C_OBTURATION_FP,
  P_FCV,
  P_ABRASION_BUCCAL,
  P_CARIE_OCCLUSAL,
  P_CARIE_BOSSE_MB,
  P_CARIE_BOSSE_DB,
  P_CARIE_BOSSE_ML,
  P_CARIE_BOSSE_DL,
  C_CARE,
  C_MATERIALS_COMPOSITE,
  C_MATERIALS_CERAMIC,
  C_MATERIALS_GOLD,
  C_MATERIALS_BASEMETAL,
  C_MATERIALS_PROVISIONAL,
  C_MATERIALS_AMALGAM,
  C_OBTURATION_BML,
  C_OBTURATION_BDL,
  P_O_BOSSE_DB,
  P_O_BOSSE_MB,
  C_OBTURATION_LC,
  C_OBTURATION_L,
  TOOTH,
  RESET,
  INIT,
  P_DENTINE,
  P_EMAIL,
  P_PRESENTOFCAVITY,
  P_ABSENCEOFCAVITY,
  P_PULP_DAMAGE,
  P_PULP_NO_AFFECTED,
  P_C1,
  P_C2,
  P_C3,
  P_C4,
  P_PROBLEM_DEVELOP,
  C_QUALITY_SUFFICIENT,
  C_QUALITY_UNCERTAIN,
  C_QUALITY_INSUFFICIENT,
  C_DETAIL_SURPLUS,
  C_DETAIL_FLUSH,
  C_DETAIL_DEFICIT,
  C_CROWNTYPE_SIMPLECROWN,
  C_CROWNTYPE_PILLAR,
  C_CROWNTYPE_PONTIC,
  C_TOOTHTYPE,
  C_IMPLANTTYPE_BL,
  C_IMPLANTTYPE_TL,
} from "../actions";

const initialState = {
  load: false,
  pathologies: {
    carie: {
      mesial: false,
      distal: false,
      palatal: false,
      buccal: false,
      lingual: false,
      cervicalLingual: false,
      palatalCervical: false,
      buccalCervical: false,
      surfacePalatal: false,
      surfaceBuccal: false,
      surfaceLingual: false,
      occlusal: false,
      incisal: false,
      bosseMesioBuccal: false,
      bosseDistoBuccal: false,
      bosseMesioLingual: false,
      bosseDistoLingual: false,
      bossePalatal: false,
      bosseLingual: false,
      cuspideBuccal: false,
      mesialClasse4: false,
      classe4Distal: false,
      dentine: {
        dentine: false,
        presenceOfCavity: false,
        absenceOfCavity: false,
        pulpDamage: false,
        pulpNotAffected: false,
        c1: false,
        c2: false,
        c3: false,
        c4: false,
      },
      email: {
        email: false,
        presenceOfCavity: false,
        absenceOfCavity: false,
        c1: false,
        c2: false,
        c3: false,
        c4: false,
      },
    },
    developmentProblem: false,
    fractureCoronaireHorizontal: false,
    fractureCoronaireVertical: false,
    fractureRadiculaireHorizontal: false,
    fractureRadiculaireVertical: false,
    apical: false,
    usureAbrasionPalatal: false,
    usureAbrasionBuccal: false,
    discolorationGrey: false,
    discolorationRed: false,
    discolorationYellow: false,
    erosion: {
      erosionPalatal: false,
      erosionBuccal: false,
      surfacePanatal: false,
      surfaceBuccal: false,
      surfaceLingual: false,
    },
  },
  cares: {
    care: null,
    couronne: false,

    partialCrown: {
      mesial: false,
      distal: false,
      buccal: false,
      palatal: false,
      occlusal: false,
      bosseMesioLingual: false,
      bosseDistoLingual: false,
      bosseMesioBuccal: false,
      bosseDistoBuccal: false,
    },
    onlay: {
      onlay: false,
      mesial: false,
      distal: false,
    },
    inlay: {
      mesial: false,
      distal: false,
    },
    obturation: {
      mesial: false,
      distal: false,
      lingual: false,
      buccal: false,
      lingualCervical: false,
      buccalCervical: false,
      bosseMesioLingual: false,
      bosseDistoLingual: false,
      bosseMesioBuccal: false,
      bosseDistoBuccal: false,
      occlusal: false,
    },
    materials: {
      composite: false,
      ceramic: false,
      gold: false,
      baseMetal: false,
      provisional: false,
      amalgam: false,
    },
  },
  quality: {
    sufficient: false,
    uncertain: false,
    insufficient: false,
  },
  detail: {
    surplus: false,
    flush: false,
    deficit: false,
  },
  crownType: {
    simplecrown: false,
    pillar: false,
  },
  implantType: {
    bonelevel: false,
    tissuelevel: false,
  },
  tooth: {
    implant: false,
    pontic: false,
    missing: false,
    dental: false,
  },
};

const teeth46 = (state = initialState, action) => {
  switch (action.type) {
    //REQUEST
    case INIT + 46:
      return {
        ...state,
        ...action?.payload,
        load: true,
      };
    //RESET
    case RESET + 46:
      if (action.payload === "all") {
        return {
          ...state,
          pathologies: initialState.pathologies,
          cares: initialState.cares,
          tooth: initialState.tooth,
        };
      } else {
        return {
          ...state,
          pathologies:
            action.payload === "pathologies"
              ? initialState.pathologies
              : state.pathologies,
          cares: action.payload === "cares" ? initialState.cares : state.cares,
          tooth: action.payload === "cares" ? initialState.tooth : state.tooth,
        };
      }
    //PROBLEM_DEVELOP
    case P_PROBLEM_DEVELOP + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          developmentProblem: !state.pathologies.developmentProblem,
        },
      };
    //DENTINE & EMAIL
    case P_DENTINE + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              dentine: !state.pathologies.carie.dentine.dentine,
            },
            email: {
              ...state.pathologies.carie.email,
              email: false,
            },
          },
        },
      };
    case P_EMAIL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            email: {
              ...state.pathologies.carie.email,
              email: !state.pathologies.carie.email.email,
            },
            dentine: {
              ...state.pathologies.carie.dentine,
              dentine: false,
            },
          },
        },
      };

    case P_PRESENTOFCAVITY + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              presenceOfCavity: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.presenceOfCavity
                : false,
              absenceOfCavity: false,
            },
            email: {
              ...state.pathologies.carie.email,
              presenceOfCavity: state.pathologies.carie.email.email
                ? !state.pathologies.carie.email.presenceOfCavity
                : false,
              absenceOfCavity: false,
            },
          },
        },
      };
    case P_ABSENCEOFCAVITY + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              presenceOfCavity: false,
              absenceOfCavity: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.absenceOfCavity
                : false,
            },
            email: {
              ...state.pathologies.carie.email,
              presenceOfCavity: false,
              absenceOfCavity: state.pathologies.carie.email.email
                ? !state.pathologies.carie.email.absenceOfCavity
                : false,
            },
          },
        },
      };

    case P_PULP_DAMAGE + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              pulpDamage: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.pulpDamage
                : false,
              pulpNotAffected: false,
            },
          },
        },
      };
    case P_PULP_NO_AFFECTED + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              pulpDamage: false,
              pulpNotAffected: state.pathologies.carie.dentine.dentine
                ? !state.pathologies.carie.dentine.pulpNotAffected
                : false,
            },
          },
        },
      };
    case P_C1 + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: state.pathologies.carie.dentine.dentine && true,
              c2: false,
              c3: false,
              c4: false,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: state.pathologies.carie.email.email && true,
              c2: false,
              c3: false,
              c4: false,
            },
          },
        },
      };
    case P_C2 + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: false,
              c2: state.pathologies.carie.dentine.dentine && true,
              c3: false,
              c4: false,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: false,
              c2: state.pathologies.carie.email.email && true,
              c3: false,
              c4: false,
            },
          },
        },
      };
    case P_C3 + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: false,
              c2: false,
              c3: state.pathologies.carie.dentine.dentine && true,
              c4: false,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: false,
              c2: false,
              c3: state.pathologies.carie.email.email && true,
              c4: false,
            },
          },
        },
      };
    case P_C4 + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            dentine: {
              ...state.pathologies.carie.dentine,
              c1: false,
              c2: false,
              c3: false,
              c4: state.pathologies.carie.dentine.dentine && true,
            },
            email: {
              ...state.pathologies.carie.email,
              c1: false,
              c2: false,
              c3: false,
              c4: state.pathologies.carie.email.email && true,
            },
          },
        },
      };
    //TOOTH TYPE
    case TOOTH + 46:
      return {
        ...state,
        tooth: {
          ...state.tooth,
          implant: action.payload === "implant" ? !state.tooth.implant : false,
          pontic: action.payload === "pontic" ? !state.tooth.pontic : false,
          missing: action.payload === "missing" ? !state.tooth.missing : false,
        },
      };
    //PATHOLOGIES
    case P_CARIE_MESIAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            mesial: !state.pathologies.carie.mesial,
          },
        },
      };
    case P_CARIE_DISTAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            distal: !state.pathologies.carie.distal,
          },
        },
      };
    case P_CARIE_PALATAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            palatal: !state.pathologies.carie.palatal,
          },
        },
      };
    case P_CARIE_BUCCAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            buccal: !state.pathologies.carie.buccal,
          },
        },
      };
    case P_CARIE_LINGUAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            lingual: !state.pathologies.carie.lingual,
          },
        },
      };
    case P_CARIE_PALATAL_CERVICAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            palatalCervical: !state.pathologies.carie.palatalCervical,
          },
        },
      };
    case P_CARIE_BUCCAL_CERVICAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            buccalCervical: !state.pathologies.carie.buccalCervical,
          },
        },
      };
    case P_CARIE_CERVICAL_LINGUAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            cervicalLingual: !state.pathologies.carie.cervicalLingual,
          },
        },
      };
    case P_CARIE_SURFACE_PALATAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            surfacePalatal: !state.pathologies.carie.surfacePalatal,
          },
        },
      };
    case P_CARIE_SURFACE_LINGUAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            surfaceLingual: !state.pathologies.carie.surfaceLingual,
          },
        },
      };
    case P_CARIE_SURFACE_BUCCAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            surfaceBuccal: !state.pathologies.carie.surfaceBuccal,
          },
        },
      };
    case P_CARIE_OCCLUSAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            occlusal:
              action.payload === "occlusal" &&
              !state.pathologies.carie.occlusal,
          },
        },
      };
    case P_CARIE_BOSSE_MB + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            bosseMesioBuccal:
              action.payload === "bossmesiobuccal" &&
              !state.pathologies.carie.bosseMesioBuccal,
          },
        },
      };
    case P_CARIE_BOSSE_DB + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            bosseDistoBuccal:
              action.payload === "bossdistobuccal" &&
              !state.pathologies.carie.bosseDistoBuccal,
          },
        },
      };
    case P_CARIE_BOSSE_ML + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            bosseMesioLingual:
              action.payload === "bossmesiolingual" &&
              !state.pathologies.carie.bosseMesioLingual,
          },
        },
      };
    case P_CARIE_BOSSE_DL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            bosseDistoLingual:
              action.payload === "bossdistolingual" &&
              !state.pathologies.carie.bosseDistoLingual,
          },
        },
      };
    case P_CARIE_BOSSE_L + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            bosseLingual:
              action.payload === "bosslingual" &&
              !state.pathologies.carie.bosseLingual,
          },
        },
      };
    case P_CARIE_CUSPIDE_B + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            cuspideBuccal:
              action.payload === "cuspidebuccal" &&
              !state.pathologies.carie.cuspideBuccal,
          },
        },
      };
    case P_CARIE_MESIAL_CLASSE4 + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            mesialClasse4: !state.pathologies.carie.mesialClasse4,
          },
        },
      };
    case P_CARIE_CLASSE4_DISTAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          carie: {
            ...state.pathologies.carie,
            classe4Distal: !state.pathologies.carie.classe4Distal,
          },
        },
      };
    case P_FCV + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureCoronaireVertical:
            !state.pathologies.fractureCoronaireVertical,
          fractureCoronaireHorizontal: false,
        },
      };
    case P_FCH + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureCoronaireHorizontal:
            !state.pathologies.fractureCoronaireHorizontal,
          fractureCoronaireVertical: false,
        },
      };
    case P_FRH + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureRadiculaireHorizontal:
            !state.pathologies.fractureRadiculaireHorizontal,
        },
      };
    case P_FRV + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          fractureRadiculaireVertical:
            !state.pathologies.fractureRadiculaireVertical,
        },
      };
    case P_APICAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          apical: action.payload === "yes" ? true : false,
        },
      };
    case P_ABRASION_PALATAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          usureAbrasionPalatal:
            action.payload === "abrasion" &&
            !state.pathologies.usureAbrasionPalatal,
        },
      };
    case P_ABRASION_BUCCAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          usureAbrasionBuccal:
            action.payload === "abrasion" &&
            !state.pathologies.usureAbrasionBuccal,
        },
      };
    case P_DG + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          discolorationGrey:
            action.payload === "grey" && !state.pathologies.discolorationGrey,
          discolorationRed: false,
          discolorationYellow: false,
        },
      };
    case P_DR + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          discolorationRed:
            action.payload === "red" && !state.pathologies.discolorationRed,
          discolorationGrey: false,
          discolorationYellow: false,
        },
      };
    case P_DY + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          discolorationYellow:
            action.payload === "yellow" &&
            !state.pathologies.discolorationYellow,
          discolorationGrey: false,
          discolorationRed: false,
        },
      };
    case P_EROSION_PALATAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            erosionPalatal:
              action.payload === "erosion" &&
              !state.pathologies.erosion.erosionPalatal,
          },
        },
      };
    case P_EROSION_BUCCAL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            erosionBuccal:
              action.payload === "erosion" &&
              !state.pathologies.erosion.erosionBuccal,
          },
        },
      };
    case P_EROSION_SL + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            surfaceLingual: !state.pathologies.erosion.surfaceLingual,
          },
        },
      };
    case P_EROSION_SB + 46:
      return {
        ...state,
        pathologies: {
          ...state.pathologies,
          erosion: {
            ...state.pathologies.erosion,
            surfaceBuccal: !state.pathologies.erosion.surfaceBuccal,
          },
        },
      };

    //CARES
    case C_CARE + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          care: action.payload,
          couronne: action.payload === "crown" && !state.cares.couronne,
          obturation: {
            ...initialState.cares.obturation,
            mesial:
              (action.payload === "inlay" ||
                action.payload === "partialcrown") &&
              true,
            distal:
              (action.payload === "inlay" ||
                action.payload === "partialcrown") &&
              true,
            occlusal:
              (action.payload === "inlay" ||
                action.payload === "partialcrown") &&
              true,
          },
          facette: {
            ...initialState.cares.facette,
            facetteBuccal: action.payload === "facet" && true,
            mesial: action.payload === "facet" && true,
            distal: action.payload === "facet" && true,
          },
          onlay: {
            ...initialState.cares.onlay,
            onlay: action.payload === "onlay" && !state.cares.onlay.onlay,
          },
          partialCrown: {
            ...initialState.cares.partialCrown,
            mesial: action.payload === "partialcrown" && true,
            distal: action.payload === "partialcrown" && true,
            buccal: action.payload === "partialcrown" && true,
            cuspideBuccal: action.payload === "partialcrown" && true,
            occlusal: action.payload === "partialcrown" && true,
          },
          materials: {
            ...initialState.cares.materials,
          },
        },
      };
    case C_COURONNE + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          couronne: !state.cares.couronne,
        },
      };

    case C_OBTURATION_M + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            mesial: !state.cares.obturation.mesial,
          },
        },
      };
    case C_OBTURATION_D + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            distal: !state.cares.obturation.distal,
          },
        },
      };
    case C_OBTURATION_L + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            lingual: !state.cares.obturation.lingual,
          },
        },
      };
    case C_OBTURATION_B + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            buccal: !state.cares.obturation.buccal,
          },
        },
      };
    case C_OBTURATION_LC + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            lingualCervical: !state.cares.obturation.lingualCervical,
          },
        },
      };
    case C_OBTURATION_BC + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            buccalCervical: !state.cares.obturation.buccalCervical,
          },
        },
      };
    case P_O_BOSSE_MB + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            bosseMesioBuccal:
              action.payload === "bossemesiobuccal" &&
              !state.cares.obturation.bosseMesioBuccal,
          },
        },
      };
    case P_O_BOSSE_DB + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            bosseDistoBuccal:
              action.payload === "bossedistobuccal" &&
              !state.cares.obturation.bosseDistoBuccal,
          },
        },
      };
    case C_OBTURATION_BML + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            bosseMesioLingual:
              action.payload === "bossemesiolingual" &&
              !state.cares.obturation.bosseMesioLingual,
          },
        },
      };
    case C_OBTURATION_BDL + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          obturation: {
            ...state.cares.obturation,
            bosseDistoLingual:
              action.payload === "bossedistolingual" &&
              !state.cares.obturation.bosseDistoLingual,
          },
        },
      };

    //MATERIAL
    case C_MATERIALS_COMPOSITE + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            composite: !state.cares.materials.composite,
            ceramic: false,
            gold: false,
            baseMetal: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_CERAMIC + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            ceramic: !state.cares.materials.ceramic,
            composite: false,
            gold: false,
            baseMetal: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_GOLD + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            gold: !state.cares.materials.gold,
            composite: false,
            ceramic: false,
            baseMetal: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_BASEMETAL + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            baseMetal: !state.cares.materials.baseMetal,
            composite: false,
            ceramic: false,
            gold: false,
            provisional: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_PROVISIONAL + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            provisional: !state.cares.materials.provisional,
            composite: false,
            ceramic: false,
            gold: false,
            baseMetal: false,
            amalgam: false,
          },
        },
      };
    case C_MATERIALS_AMALGAM + 46:
      return {
        ...state,
        cares: {
          ...state.cares,
          materials: {
            ...state.cares.materials,
            amalgam: !state.cares.materials.amalgam,
            composite: false,
            ceramic: false,
            gold: false,
            baseMetal: false,
            provisional: false,
          },
        },
      };
    // case C_PARTIALCROWN_B + 46:
    //   return {
    //     ...state,
    //     cares: {
    //       ...state.cares,
    //       partialCrown: {
    //         ...state.cares.partialCrown,
    //         buccal:
    //           action.payload === "buccal" && !state.pathologies.carie.buccal,
    //       },
    //     },
    //   };
    // case C_PARTIALCROWN_P + 46:
    //   return {
    //     ...state,
    //     cares: {
    //       ...state.cares,
    //       partialCrown: {
    //         ...state.cares.partialCrown,
    //         palatal:
    //           action.payload === "palatal" && !state.pathologies.carie.palatal,
    //       },
    //     },
    //   };
    // case C_PARTIALCROWN_CB + 46:
    //   return {
    //     ...state,
    //     cares: {
    //       ...state.cares,
    //       partialCrown: {
    //         ...state.cares.partialCrown,
    //         cuspideBuccal:
    //           action.payload === "cuspidebuccal" &&
    //           !state.pathologies.carie.cuspideBuccal,
    //       },
    //     },
    //   };
    // case C_PARTIALCROWN_BL + 46:
    //   return {
    //     ...state,
    //     cares: {
    //       ...state.cares,
    //       partialCrown: {
    //         ...state.cares.partialCrown,
    //         bossePalatal:
    //           action.payload === "bossepalatal" &&
    //           !state.pathologies.carie.bossePalatal,
    //       },
    //     },
    //   };
    case C_QUALITY_SUFFICIENT + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          quality: {
            ...state?.cares?.quality,
            sufficient: !state?.cares?.quality?.sufficient,
            uncertain: false,
            insufficient: false,
          },
        },
      };
    case C_QUALITY_UNCERTAIN + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          quality: {
            ...state?.cares?.quality,
            sufficient: false,
            uncertain: !state?.cares?.quality?.uncertain,
            insufficient: false,
          },
        },
      };
    case C_QUALITY_INSUFFICIENT + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          quality: {
            ...state?.cares?.quality,
            sufficient: false,
            uncertain: false,
            insufficient: !state?.cares?.quality?.insufficient,
          },
        },
      };
    case C_DETAIL_SURPLUS + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          detail: {
            surplus: !state?.cares?.detail?.surplus,
            flush: false,
            deficit: false,
          },
        },
      };
    case C_DETAIL_FLUSH + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          detail: {
            ...state?.cares?.detail,
            surplus: false,
            flush: !state?.cares?.detail?.flush,
            deficit: false,
          },
        },
      };
    case C_DETAIL_DEFICIT + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          detail: {
            ...state?.cares?.detail,
            surplus: false,
            flush: false,
            deficit: !state?.cares?.detail?.deficit,
          },
        },
      };
    case C_CROWNTYPE_SIMPLECROWN + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          crownType: {
            ...state?.cares?.crownType,
            simplecrown: !state?.cares?.crownType?.simplecrown,
            pillar: false,
          },
        },
        tooth: {
          ...state?.tooth,
          pontic: false,
        },
      };
    case C_CROWNTYPE_PILLAR + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          crownType: {
            ...state?.cares?.crownType,
            simplecrown: false,
            pillar: !state?.cares?.crownType?.pillar,
          },
        },
        tooth: {
          ...state?.tooth,
          pontic: false,
        },
      };
    case C_CROWNTYPE_PONTIC + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          crownType: {
            ...state?.cares?.crownType,
            simplecrown: false,
            pillar: false,
          },
        },
        tooth: {
          ...state?.tooth,
          pontic: !state?.tooth?.pontic,
        },
      };
    case C_TOOTHTYPE + 46:
      return {
        ...state,
        tooth: {
          ...state?.tooth,
          dental: action.payload === "dental" ? !state?.tooth?.dental : false,
          implant:
            action.payload === "implant" ? !state?.tooth?.implant : false,
        },
      };
    case C_IMPLANTTYPE_BL + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          implantType: {
            ...state?.cares?.implantType,
            bonelevel: !state?.cares?.implantType?.bonelevel,
            tissuelevel: false,
          },
        },
      };
    case C_IMPLANTTYPE_TL + 46:
      return {
        ...state,
        cares: {
          ...state?.cares,
          implantType: {
            ...state?.cares?.implantType,
            bonelevel: false,
            tissuelevel: !state?.cares?.implantType?.tissuelevel,
          },
        },
      };
    default:
      return state;
  }
};

export default teeth46;
