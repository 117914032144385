// export const BASE_URI = 'http://localhost:5000/api/';
export const is_local =
  typeof window !== 'undefined' &&
  ['localhost', ''].includes(window?.location?.hostname);

export const BASE_URI = is_local
  ? 'http://localhost:5002/api/'
  : 'https://api.mediweb.lu/api/';

export const COMPARATOR_BASE_URI = is_local
  ? 'http://localhost:3000/'
  : 'https://comparatorpic.netlify.app/';

const PROD_ENDPOINT_URI = 'wss://api.mediweb.lu/api';
const DEV_ENDPOINT_URI = 'ws://localhost:5000';
export const BASE_ENDPOINT = is_local ? DEV_ENDPOINT_URI : PROD_ENDPOINT_URI;
