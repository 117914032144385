export const Button = ({
  label,
  bgColor = '#2174A1',
  color = '#fff',
  onClick,
  margin,
  upperCase = false,
  width,
  border = false,
  disabled = false,
  className = '',
}) => {
  return (
    <button
      className={`button ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        margin,
        width,
        backgroundColor: border ? 'white' : bgColor,
        color,
        textTransform: upperCase ? 'uppercase' : null,
        border: border && '1px solid #D1D5DB',
        boxShadow: border && '0px 1px 2px rgba(0, 0, 0, 0.05)',
      }}
    >
      {label}
    </button>
  );
};

export const YesOrNoButton = ({
  value,
  choice,
  handleChange,
  label,
  disabled,
}) => {
  return (
    <button
      type='button'
      className='anamnesModule--form-responseBtn'
      onClick={() => handleChange(value)}
      disabled={disabled}
    >
      {label}
      <span
        className={
          choice === value
            ? 'anamnesModule--form-responseBtn-selected'
            : undefined
        }
      />
    </button>
  );
};
