import { SET_INFO } from "./actions";

const initialState = {
  user: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default user;
